import { Toast } from 'antd-mobile';

export function compress(res) {
  // console.log('res:', res)
  // const maxWidth = 600;
  // const maxHeight = 375;
  const maxWidth = 800;
  const maxHeight = 500;
  const fileType = 'base64'; //'base64', 'blob'
  const imageType = 'image/jpeg'; //'image/jpeg','image/png'
  /*canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/png';
   * qualityArgument表示导出的图片质量，只有导出为jpeg和webp格式的时候此参数才有效，默认值是0.92*/
  // imageType = `image/${imageType}`

  return new Promise(function(resolve, reject) {
    const img = new Image();

    img.onload = function() {
      let originWidth = this.width,
        originHeight = this.height;

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      let targetWidth = originWidth,
        targetHeight = originHeight;
      // 图片尺寸超过400x400的限制
      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > maxWidth / maxHeight) {
          // 更宽，按照宽度限定尺寸
          targetWidth = maxWidth;
          targetHeight = Math.round(maxWidth * (originHeight / originWidth));
        } else {
          targetHeight = maxHeight;
          targetWidth = Math.round(maxHeight * (originWidth / originHeight));
        }
      }

      // canvas对图片进行缩放
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      // 清除画布
      context.clearRect(0, 0, targetWidth, targetHeight);
      // 图片压缩
      context.drawImage(img, 0, 0, targetWidth, targetHeight);

      if (fileType === 'base64') {
        // 获取base64格式信息
        const dataUrl = canvas.toDataURL(imageType, 0.92);
        resolve(dataUrl);
      } else {
        // 把canvas转化为blob二进制文件
        if (canvas.toBlob) {
          canvas.toBlob(function(blob) {
            resolve(blob);
          }, imageType);
        } else {
          // ios 不支持toB
          let data = canvas.toDataURL(imageType);
          //dataURL 的格式为 “data:image/png;base64,****”,逗号之前都是一些说明性的文字，我们只需要逗号之后的就行了
          data = data.split(',')[1];
          data = window.atob(data);
          let ia = new Uint8Array(data.length);
          for (let i = 0; i < data.length; i++) {
            ia[i] = data.charCodeAt(i);
          }
          //canvas.toDataURL 返回的默认格式就是 image/png
          let blob = new Blob([ia], {
            type: imageType,
          });
          resolve(blob);
        }
      }
    };

    img.onerror = function() {
      reject(new Error('图片加载失败'));
    };

    img.src = res;
  });
}

// 读取文件并转化为base64
export function fileReader(file) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();

    reader.onload = e => {
      resolve(e.target.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export function getUrlParameter(name) {
  name = name
    .replace(/[]/, '[')
    .replace(/[]/, '[')
    .replace(/[]/, '\\]');
  var regexS = '[\\?&]' + name + '=([^&#]*)';
  var regex = new RegExp(regexS);
  var results = regex.exec(window.parent.location.href);
  if (results === null) return '';
  else {
    return results[1];
  }
}

// 将base64转换为文件
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

//微信版本bug解决方法：在苹果手机上，当输入框失去焦点，软键盘隐藏后，输入框不会下滑到底部，并且与底部之间留有大片空白
export function resolveAppleInputBug(e) {
  try {
    setTimeout(function() {
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        return;
      }
      let result = 'pc';
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //判断iPhone|iPad|iPod|iOS
        result = 'ios';
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //判断Android
        result = 'android';
      }
      if ((result = 'ios')) {
        document.activeElement.scrollIntoViewIfNeeded(true);
      }
    }, 10);
  } catch (err) {}
}

// 获取微信签名串并且注册wxjssdk方法
export function wxGetSignature(url) {
  const wxJdk = window.wx;
  const maps = {
    // url: encodeURIComponent(url),
    url: url,
  };
  window.g_app._store.dispatch({
    type: 'index/getWeixinSignature',
    maps,
    callback: r => {
      if (r.code === 1) {
        const result = JSON.parse(r.result);
        if (result.code === 0) {
          console.log('config:', url, result.data, process.env.appid);
          wxJdk.config({
            debug: false, // !process.env.prod 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: process.env.appid, // 必填，公众号的唯一标识
            timestamp: result.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.data.noncestr, // 必填，生成签名的随机串
            signature: result.data.signStr, // 必填，签名
            jsApiList: [
              'chooseImage',
              'getLocalImgData',
              // 'requestWxFacePictureVerifyUnionVideo',
              'checkIsSupportFaceDetect',
              'getLocation',
              'requestWxFacePictureVerify',
            ], // 必填，需要使用的JS接口列表
          });
        } else {
          window.g_app._store.dispatch({
            type: 'index/showGlobalModal',
            msg: result.message ? result.message : '获取签名出错，请退出微信重试',
          });
        }
      } else {
        window.g_app._store.dispatch({
          type: 'index/showGlobalModal',
          msg: r.msg ? r.msg : '获取签名出错，请退出微信重试',
        });
      }
    },
  });
}

// 获取微信签名串并且注册wxjssdk方法：云账户
export function wxGetSignatureAccount(url) {
  const wxJdk = window.wx;
  const maps = {
    // url: encodeURIComponent(url),
    url: url,
  };
  window.g_app._store.dispatch({
    type: 'account/getWeixinSignature',
    maps,
    callback: r => {
      if (r.code === 0) {
        console.log('config:', url, r.data, process.env.appid);
        wxJdk.config({
          debug: false, // !process.env.prod 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: process.env.appid, // 必填，公众号的唯一标识
          timestamp: r.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: r.data.noncestr, // 必填，生成签名的随机串
          signature: r.data.signStr, // 必填，签名
          jsApiList: [
            'chooseImage',
            'getLocalImgData',
            // 'requestWxFacePictureVerifyUnionVideo',
            'checkIsSupportFaceDetect',
            'getLocation',
            'requestWxFacePictureVerify',
          ], // 必填，需要使用的JS接口列表
        });
      } else {
        window.g_app._store.dispatch({
          type: 'index/showGlobalModal',
          msg: r.message ? r.message : '获取签名出错，请退出微信重试',
        });
      }
    },
  });
}

// wx选择图片并返回base64
export function wxChooseImg() {
  return new Promise(function(resolve, reject) {
    window.wx.checkJsApi({
      jsApiList: ['chooseImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      success: function(checkJsApiRes) {
        if (checkJsApiRes.checkResult && checkJsApiRes.checkResult.chooseImage) {
          window.wx.chooseImage({
            count: 1, // 默认9
            sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有 'original',
            sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
            success: function(chooseImageRes) {
              // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
              // 先上传，获取url之后展示url
              window.wx.getLocalImgData({
                localId: chooseImageRes.localIds[0], // 图片的localID
                success: function(getLocalImgDataRes) {
                  // localData是图片的base64数据，可以用img标签显示
                  var localData = getLocalImgDataRes.localData;
                  // 带有base64头部
                  if (localData.indexOf('data:image') !== 0) {
                    //判断是否有这样的头部
                    localData = 'data:image/jpeg;base64,' + localData;
                  }
                  // localData = localData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg')
                  // 无压缩
                  // if (localData.indexOf('data:image') !== -1) {
                  //     //判断是否有这样的头部
                  //     localData = localData.split(',')[1]
                  // }
                  // resolve(localData)
                  // 压缩
                  compress(localData).then(
                    function(vLocalData) {
                      // 不带有base64头部
                      if (vLocalData.indexOf('data:image') !== -1) {
                        //判断是否有这样的头部
                        vLocalData = vLocalData.split(',')[1];
                      }
                      resolve(vLocalData);
                    },
                    function(error) {
                      reject(error);
                    },
                  );
                },
                fail: function(getLocalImgDataFailedRes) {
                  reject('获取图片文件失败，请重试');
                },
              });
            },
            fail: function(chooseImageFailedRes) {
              reject('获取图片失败，请重试');
            },
          });
        } else {
          reject('手机不支持选择照片后拍照，请重试或更换设备');
        }
      },
    });
  });
}

// 微信人脸识别
export function wxFaceId(name, idCardNum) {
  return new Promise(function(resolve, reject) {
    const userInfo = JSON.stringify({
      id_card_number: idCardNum,
      name: name,
    });
    window.wx.invoke(
      // 'requestWxFacePictureVerifyUnionVideo',
      'requestWxFacePictureVerify',
      {
        appid: process.env.appid, // 必填，公众号的唯一标识
        check_alive_type: 2,
        request_verify_pre_info: userInfo,
      },
      function(res) {
        if (res.err_code === 0 || res.err_code === '0') {
          // console.log('requestWxFacePictureVerifyUnionVideo', res);
          if (res.verify_result) {
            localStorage.setItem('wxface', res.verify_result);
          }
          resolve(res);
        } else {
          if (!process.env.prod) {
            resolve(res);
            window.g_app._store.dispatch({
              type: 'index/showGlobalModal',
              msg: '测试环境：人脸识别让你过',
            });
          } else {
            // var ret = res.err_msg;
            var ret = '人脸识别失败，请重试';
            ret += ' : ' + res.err_code;
            reject(ret);
          }
        }
      },
    );
  });
}

// 获取省市区
export function getAddressByAreaData(areaData, liveCity, isArrow) {
  let result = liveCity
    ? areaData.find(obj => obj.value === liveCity[0]).label +
      (isArrow ? '->' : '') +
      areaData
        .find(obj => obj.value === liveCity[0])
        .children.find(obj => obj.value === liveCity[1]).label +
      (isArrow ? '->' : '') +
      areaData
        .find(obj => obj.value === liveCity[0])
        .children.find(obj => obj.value === liveCity[1])
        .children.find(obj => obj.value === liveCity[2]).label
    : '';
  return result;
}

// 获取双层级联数据的展示数据
export function get2LinkageShowData(data, chooseItem) {
  try {
    let result = chooseItem
      ? data
          .find(obj => obj.value === chooseItem[0])
          .children.find(obj => obj.value === chooseItem[1]).label
      : '';
    return result;
  } catch (e) {
    return '';
  }
}

// fix touch to scroll background page on iOS
export function onWrapTouchStart(e) {
  if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
    return;
  }
  const pNode = closest(e.target, '.am-modal-content');
  if (!pNode) {
    e.preventDefault();
  }
}

function closest(el, selector) {
  const matchesSelector =
    el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

export function getUserInfo() {
  try {
    return localStorage.userInfo ? JSON.parse(localStorage.userInfo) : '';
  } catch (e) {
    return '';
  }
}

export function getTermList() {
  return localStorage.termList ? JSON.parse(localStorage.termList) : '';
}

export function getAnjuCSignContractInfo() {
  return localStorage.anjuCSignContractInfo ? JSON.parse(localStorage.anjuCSignContractInfo) : '';
}

export function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

export function trim(str, is_global) {
  var result;
  result = str.replace(/(^\s+)|(\s+$)/g, '');
  if (is_global.toLowerCase() === 'g') {
    result = result.replace(/\s/g, '');
  }
  return result;
}

export function showLoading() {
  window.g_app._store.dispatch({
    type: 'index/setManualLoading',
    manualLoading: true,
  });
}

export function hideLoading() {
  window.g_app._store.dispatch({
    type: 'index/setManualLoading',
    manualLoading: false,
  });
}

// 金额格式化

export function numberFormat(number, decimals, dec_point, thousands_sep) {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.ceil(n * k) / k;
    };

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

//将数字转换成金额显示
export function toMoney(s) {
  // n = n > 0 && n <= 20 ? n : 2;
  let n = 2;
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
  var l = s
      .split('.')[0]
      .split('')
      .reverse(),
    r = s.split('.')[1];
  var t = '';

  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
  }
  return (
    t
      .split('')
      .reverse()
      .join('') +
    '.' +
    r
  );
}

// 脱敏 身份证
export function certificateCode(s) {
  if (s) {
    return s.replace(/^(.{3})(?:\d+)(.{4})$/, '$1****$2');
  } else {
    return '---';
  }
}

// 脱敏 银行卡
export function bankCode(s) {
  if (s) {
    return s.replace(/^(.{4})(?:\d+)(.{4})$/, '$1 **** **** $2');
  } else {
    return '---';
  }
}

// 脱敏 手机号码
export function phoneDesensitization(str) {
  if (str) {
    var pat = /(\d{3})\d*(\d{4})/;
    return str.replace(pat, '$1****$2');
  } else {
    return '---';
  }
}

// 控制只能输入数字并且最多允许小数点两位
export function numInputLimit(s) {
  let k1 = s.replace(/[^\d.]/g, '');
  let k2 = k1.replace(/\.{2,}/g, '.');
  let k3 = k2.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
  return k3;
}

// 复制到剪切板

export function copyToClipboard(value) {
  var dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', value);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  Toast.success('已复制到粘贴板', 2);
}

export function parseQuery(obj) {
  let str = '';
  for (let key in obj) {
    const value = typeof obj[key] !== 'string' ? JSON.stringify(obj[key]) : obj[key];
    str += '&' + key + '=' + value;
  }
  return str.substr(1);
}

// true:数值型的，false：非数值型
export function myIsNaN(value) {
  return typeof value === 'number' && !isNaN(value);
}

// 判断是否在微信浏览器内
export function isWeiXinEnv() {
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin = ua.indexOf('micromessenger') != -1;
  return isWeixin;
}

// 判空
export function isEmpty(obj) {
  if (typeof obj === 'undefined' || obj == null || obj === '') {
    return true;
  } else {
    return false;
  }
}
