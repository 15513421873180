import request from '../utils/request';

// 测试服务器：
const weChatPrefix = process.env.weChat_url_prefix;

// 安心签电子签署
export function geturl(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? weChatPrefix + 'lstquery/geturl'
      : '/wechat_api/lstquery/geturl',
    'post',
    props,
  );
}

// 电子协议
export function downloadPro(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? weChatPrefix + 'lstquery/downloadPro'
      : '/wechat_api/lstquery/downloadPro',
    'post',
    props,
  );
}

