import React, { useState, useEffect } from 'react';
import rStyles from '../index.less';
import { WhiteSpace, Button, Toast, Checkbox, NoticeBar } from 'antd-mobile';
import { Form } from 'antd';
import { connect } from 'dva';
import router from 'umi/router';
import * as reg from '../utils/reg';
import * as utils from '../utils/common';
import { InputField } from '../../component/fieldItemHook';
// import VConsole from 'vconsole';
import { startRecord } from '../utils/situUtils';

const AgreeItem = Checkbox.AgreeItem;
const wxJdk = window.wx;

function SignEntrance(props) {
  const [btnMsg, setBtnMsg] = useState('发送验证码');
  const [isSubmitBtnEnable, setIsSubmitBtnEnable] = useState(false);
  const [form] = Form.useForm();
  const { getFieldValue } = form;

  useEffect(() => {
    document.title = '合同签署';
    // 说明是从face的识别页面跳转回来的，这时候利用biz_id来获取结果。
    if (localStorage.biz_id) {
      const maps = {
        bizId: localStorage.biz_id,
      };
      props.dispatch({
        type: 'sign/h5getResult',
        maps,
        callback: r => {
          let result = JSON.parse(r.result);
          console.log('h5getResult', result);
          if (result.code === 0) {
            let vResult = JSON.parse(result.data);
            if (
              vResult &&
              vResult.verify_result &&
              vResult.verify_result.result_faceid &&
              vResult.verify_result.result_faceid.confidence &&
              vResult.verify_result.result_faceid.thresholds['1e-6']
            ) {
              localStorage.setItem('faceidResult', JSON.stringify(vResult.verify_result));
              if (
                vResult.verify_result.result_faceid.confidence >=
                vResult.verify_result.result_faceid.thresholds['1e-6']
              ) {
                // 人脸识别通过
                goNext();
              } else {
                props.dispatch({
                  type: 'index/showGlobalModal',
                  msg: '人脸识别不通过，请重试或者联系客户经理',
                });
                if (!process.env.prod) {
                  goNext();
                  Toast.info('测试环境，让你通过', 1);
                }
              }
            } else {
              if (!process.env.prod) {
                goNext();
                Toast.info('测试环境，让你通过', 1);
              }
              props.dispatch({
                type: 'index/showGlobalModal',
                msg: '获取人脸识别结果出错，稍后重试：0',
              });
            }
          } else {
            if (!process.env.prod) {
              goNext();
              Toast.info('测试环境，让你通过', 1);
            }
            props.dispatch({
              type: 'index/showGlobalModal',
              msg: '获取人脸识别结果出错，稍后重试：1',
            });
          }
        },
      });
    } else {
      props.dispatch({
        type: 'sign/resetState',
      });
    }

    // if (!process.env.prod) {
    //   var vConsole = new VConsole();
    // form.setFieldsValue({
    //   name: '吴廷',
    //   idcard: '429006199408228232',
    // });
    // Toast.info('biz_id:' + localStorage.biz_id, 1);
    // }

    utils.showLoading();
    // 清除 situTaskId，清除上次签署的痕迹
    localStorage.removeItem('situTaskId');
    setTimeout(() => {
      utils.hideLoading();
      if (window.SituRecorder) {
        startRecord()
          .then(r => {
            if (!process.env.prod) {
              Toast.success('测试环境才有的提示：启动可回溯，sessionStorage.situ');
            }
            localStorage.setItem('taskId', r);
          })
          .catch(e => {
            console.log('启动可回溯失败');
          });
      } else {
        window.location.reload();
      }
    }, 2000);

    return () => {};
  }, []);

  function handleSubmit() {
    console.log('handleSubmit---0');
    utils.resolveAppleInputBug();
    let _this = this;
    let array = ['name', 'idcard'];
    // 先人脸识别
    // 再获取签署列表
    form
      .validateFields()
      .then(values => {
        console.log('values', values);
        const maps = {
          cusName: values.name,
          userName: values.name,
          certCode: values.idcard,
          certNo: values.idcard,
          notifyUrl: process.env.home,
          sceneId: 'signContract',
        };
        localStorage.setItem('cusName', maps.cusName);
        localStorage.setItem('certCode', maps.certCode);
        // if (!process.env.prod) {
        //   props.dispatch({
        //     type: 'sign/getSignSernoTaskList',
        //     maps,
        //     callback: r => {
        //       router.push({
        //         pathname: '/ElectronicAgreementList',
        //       });
        //     },
        //   });
        // } else {
        //   utils.wxGetSignatureAccount(window.location.href);
        // }

        if (utils.isWeiXinEnv()) {
          // 如果是微信浏览器内，就调用微信的人脸识别
          utils.wxGetSignatureAccount(window.location.href);
        } else {
          // 如果不是在微信浏览器内，就调用faceid的人脸识别
          props.dispatch({
            type: 'sign/h5FaceToken',
            maps,
            callback: r => {
              let result = JSON.parse(r.result);
              if (result.code === 0 && result.data && result.data.token && result.data.biz_id) {
                console.log('h5FaceToken', result.data.token);
                localStorage.setItem('biz_id', result.data.biz_id);
                // 用于保存人脸识别流水号，biz_id 要用于逻辑判断，所以增加一个 bizId 用于保存
                localStorage.setItem('bizId', result.data.biz_id);
                window.location.href =
                  'https://api.megvii.com/faceid/lite/do?token=' + result.data.token;
              } else {
                props.dispatch({
                  type: 'index/showGlobalModal',
                  msg: '获取FaceToken出错，稍后重试',
                });
              }
              console.log('h5FaceToken', result.data.token);
            },
          });
        }

        wxJdk.ready(function() {
          utils.wxFaceId(values.name, values.idcard).then(
            function(value) {
              goNext();
            },
            function(error) {
              props.dispatch({
                type: 'index/showGlobalModal',
                msg: error,
              });
            },
          );
        });

        wxJdk.error(function(res) {
          props.dispatch({
            type: 'index/showGlobalModal',
            msg: '初始化失败' + JSON.stringify(res),
          });
        });
      })
      .catch(errorInfo => {
        console.log(errorInfo);
      });
  }

  function goNext() {
    const maps = {
      cusName: getFieldValue('name') || localStorage.cusName,
      certCode: getFieldValue('idcard') || localStorage.certCode,
    };
    console.log('goNext', maps);
    // 人脸识别成功
    props.dispatch({
      type: 'sign/getSignSernoTaskList',
      maps,
      callback: r => {
        router.push({
          pathname: '/ElectronicAgreementList',
        });
      },
    });
  }

  return (
    <div
      style={{
        paddingTop: '0.4rem',
        backgroundColor: '#fff',
        height: '100%',
      }}
      className={rStyles.customForm}
    >
      <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
        签署过程中，请勿刷新、关闭页面或使用浮窗功能，否则需重新进入签署页面签署。
      </NoticeBar>
      <img
        alt=""
        style={{ margin: '2rem 15vw 0rem 15vw', width: '70vw', height: '60vw' }}
        src={require('../../assets/sinosafeLogo.png')}
      />
      <WhiteSpace size="xl"></WhiteSpace>
      <WhiteSpace size="xl"></WhiteSpace>
      <WhiteSpace size="xl"></WhiteSpace>
      <Form form={form} onFinish={() => {}}>
        <InputField label="姓名" des="请输入姓名" reg={reg.name_rules} fieldName="name" />

        <InputField
          label="身份证号码"
          des="请输入本人身份证号码"
          reg={reg.idcard_rules}
          fieldName="idcard"
        />
      </Form>
      <WhiteSpace size="xl"></WhiteSpace>

      <div style={{ backgroundColor: '#ffffff', padding: '1rem 0.7rem 1rem  0.7rem' }}>
        {/* <AgreeItem
          style={{
            height: '10rem',
            textAlign: 'start',
            color: '#666666',
            fontWeight: '300',
            fontSize: '0.8rem',
          }}
          onChange={e => this.setState({ isSubmitBtnEnable: !e.target.checked })}
        >
          温馨提示：
          <br />
          -
          目前微信公众号仅支持查询华安信保部分业务，若无法查询，可前往华安保险官网或拨打95556联系客服
          <br />
        </AgreeItem> */}
        <Button
          onClick={handleSubmit}
          disabled={isSubmitBtnEnable}
          shape="round"
          size="large"
          type="primary"
          className="btn"
        >
          提交
        </Button>
        <div style={{ textAlign: 'center', marginTop: '1rem', color: 'rgba(232,232,232,0.2)' }}>
          版本号：{process.env.RELEASE_VERSION}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state.sign,
  };
}

export default connect(mapStateToProps)(SignEntrance);
