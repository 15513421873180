import styles from './fieldItemHook.css';
import rStyles from '../pages/index.less';
import { Text, Picker, DatePicker } from 'antd-mobile';
import { Form, Input } from 'antd';
const FormItem = Form.Item;

// hook??
export function InputField(props) {
  return (
    <div className={styles.itemTextLabelContainer + ' ' + rStyles.customAndtInput}>
      <Text className={styles.itemTextFormItemLabel}>{props.label}</Text>
      <Form.Item name={props.fieldName} rules={props.reg}>
        <input
          type={props.inputType ? props.inputType : ''}
          maxLength={props.maxLength ? props.maxLength : ''}
          className={styles.itemTextfield}
          style={{ width: props.inputWidth ? props.inputWidth : '16rem' }}
          placeholder={props.des}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      </Form.Item>
    </div>
  );
}

// ??????????????????
export function InputFieldAuto(props) {
  // const { setFieldsValue } = props.form
  // setFieldsValue({ [props.fieldName]: [props.des] })
  return (
    <div className={styles.itemTextLabelContainer}>
      <Text className={styles.itemTextFormItemLabel}>{props.label}</Text>
      <Form.Item name={props.fieldName} rules={props.reg}>
        <input
          disabled
          type={props.inputType ? props.inputType : ''}
          maxLength={props.maxLength ? props.maxLength : ''}
          // className={styles.itemTextfield}
          className={styles.itemTextfieldAuto}
          style={{ width: props.inputWidth ? props.inputWidth : '16rem' }}
          placeholder={props.des}
          // value={props.des}
        />
        {/* <Text className={styles.itemTextfieldAuto}>{props.des}</Text> */}
      </Form.Item>
    </div>
  );
}

export const PickerField = props => {
  return (
    <FormItem>
      {props.form.getFieldDecorator(props.fieldName, {
        initialValue: '',
        rules: props.reg,
      })(
        <Picker
          data={props.pickerData}
          onOk={v => {
            props.handleSelect(props.fieldName, v);
          }}
          cols={props.cols}
          //   value={props.fieldValue}
        >
          <div className={styles.itemTextLabelContainer}>
            <Text className={styles.itemTextFormItemLabel}>{props.label}</Text>
            <div className={styles.itemTextfieldForm}>
              <Text className={styles.chosePlaceholder}>
                {props.fieldValue ? props.fieldValue : props.des}
              </Text>
              <img
                className={styles.itemFieldImgForm}
                src={require('../assets/nextimg@2x.png')}
                alt=""
              ></img>
            </div>
          </div>
        </Picker>,
      )}
    </FormItem>
  );
};

export const DatePickerField = props => {
  return (
    <FormItem>
      {props.form.getFieldDecorator(props.fieldName, {
        initialValue: '',
        rules: props.reg,
      })(
        <DatePicker
          data={[
            { label: '是', value: '是' },
            { label: '坦', value: '坦' },
          ]}
          mode={'date'}
          maxDate={new Date()}
          onChange={v => {
            props.handleSelect(props.fieldName, v);
          }}
          //   value={this.state.indiv_work_job_y}
        >
          <div className={styles.itemTextLabelContainer}>
            <Text className={styles.itemTextFormItemLabel}>{props.label}</Text>
            <div className={styles.itemTextfieldForm}>
              <Text className={styles.chosePlaceholder}>
                {props.fieldValue ? props.fieldValue : props.des}
              </Text>
              <img
                className={styles.itemFieldImgForm}
                src={require('../assets/nextimg@2x.png')}
                alt=""
              ></img>
            </div>
          </div>
        </DatePicker>,
      )}
    </FormItem>
  );
};
