import request from '../utils/request';

// 测试服务器：
const weChatPrefix = process.env.weChat_url_prefix;
const urlPrefix = process.env.url_prefix;

// 预览合同
export function getPreviewTemplate(props) {
  let url =
    process.env.NODE_ENV === 'production'
      ? weChatPrefix + 'electsign/getPreviewTemplate'
      : '/sign_api/electsign/getPreviewTemplate';
  return url;
}

// 查询待签署合同列表
export function getSignContractList(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? weChatPrefix + 'electsign/getSignContractList'
      : '/sign_api/electsign/getSignContractList',
    'get',
    props,
  );
}

// 根据姓名和身份证号获取待签署（流水号）任务列表
export function getSignSernoTaskList(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? weChatPrefix + 'electsign/getSignSernoTaskList'
      : '/sign_api/electsign/getSignSernoTaskList',
    'post',
    props,
  );
}

// 发送电子签署短信
export function sendSignSms(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? weChatPrefix + 'electsign/sendSignSms'
      : '/sign_api/electsign/sendSignSms',
    'post',
    props,
  );
}

// 确认短信并进行签署
export function confirmSmsAndSign(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? weChatPrefix + 'electsign/confirmSmsAndSign'
      : '/sign_api/electsign/confirmSmsAndSign',
    'post',
    props,
  );
}

// faceid : Lite-GetToken , https://faceid.com/pages/documents/5680475
export function h5FaceToken(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? urlPrefix + 'v2/external/microservice/h5FaceToken'
      : '/sinosafe_api/v2/external/microservice/h5FaceToken',
    'post',
    props,
  );
}

// face:Lite-GetResult,https://faceid.com/pages/documents/5680488
export function h5getResult(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? urlPrefix + 'v2/external/microservice/h5getResult'
      : '/sinosafe_api/v2/external/microservice/h5getResult',
    'post',
    props,
  );
}

// 保存录屏任务记录
export function saveScreenInfo(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? urlPrefix + 'v2/common/saveScreenInfo'
      : '/sinosafe_api/v2/common/saveScreenInfo',
    'post',
    props,
  );
}

