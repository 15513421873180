import { Modal } from 'antd-mobile';
// import fetch from 'dva/fetch';
import * as service from '../pages/services/index';
import * as accountService from '../pages/services/account';
import * as weChatService from '../pages/services/wechat';
import router from 'umi/router';

// 云账户的models

export default {
  state: {
    title: '华安信保',
    manualLoading: false, //手动
    globalModalMsg: '',
    globalVisible: false,
    userInfo: {},
    openId: '',
    bankCardList: [], //银行卡列表
    accountDetail: [], //查询账户明细列表
    withdrawBankCard: {}, //提现选定的银行卡
    itemData: {}, //页面的列表的项的数据暂存
    wxUserInfo: {}, //微信登录的信息，头像及unionId等
  },

  reducers: {
    setWxUserInfo(state, { wxUserInfo }) {
      return { ...state, wxUserInfo };
    },
    setItemData(state, { itemData }) {
      return { ...state, itemData };
    },

    setWithdrawBankCard(state, { withdrawBankCard }) {
      return { ...state, withdrawBankCard };
    },

    setAccountDetail(state, { accountDetail }) {
      return { ...state, accountDetail };
    },

    resetState(state, {}) {
      localStorage.clear();
      console.assert(process.env.prod, 'resetState');
      return { ...state, userInfo: '', anjuCSignContractInfo: '', serno: '', isGetAuth: false };
    },

    setManualLoading(state, { manualLoading }) {
      return { ...state, manualLoading };
    },

    setBankCardList(state, { bankCardList }) {
      return { ...state, bankCardList };
    },

    setUserInfo(state, { userInfo }) {
      localStorage.setItem('cusId', userInfo.cusId);
      localStorage.setItem('token', userInfo.token);
      // 这个是用于app的token
      localStorage.setItem('jumpToken', userInfo.jumpToken);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      return { ...state, userInfo };
    },

    setOpenId(state, { openId }) {
      localStorage.setItem('openId', openId);
      return { ...state, openId };
    },

    showGlobalModal(state, { msg }) {
      return { ...state, globalVisible: true, globalModalMsg: msg };
    },

    hideGlobalModal(state) {
      return { ...state, globalVisible: false };
    },

    showMsg(state, { msg }) {
      const alertInstance = Modal.alert('提示', msg, [
        { text: '确定', onPress: () => alertInstance.close, style: 'default' },
      ]);
      return { ...state };
    },
  },

  effects: {
    *getWeixinSignature({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.getWeixinSignature, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *facePlusOcrAndUploadApi({ maps, callback }, { call, put }) {
      const resp = yield call(service.facePlusOcrAndUploadApi, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *userLogin({ mobile, code, callback }, { call, put }) {
      const resp = yield call(service.userLogin, { mobile, code, terminalType: '10' });
      if (resp && resp.code === 1) {
        yield put({
          type: 'setUserInfo',
          userInfo: resp.result,
        });
        if (callback && typeof callback === 'function') {
          callback(resp.result);
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '请求出错，稍后重试',
        });
      }
    },

    *sendCode({ maps, callback }, { call, put }) {
      const resp = yield call(service.sendCode, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *addAndCheckBankCard({ maps, callback }, { call, put }) {
      const resp = yield call(service.addAndCheckBankCard, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *editProfile({ params, callback }, { call, put }) {
      const resp = yield call(service.editProfile, { ...params });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *addOrEditUserDetail({ maps, callback }, { call, put }) {
      const resp = yield call(service.addOrEditUserDetail, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *saveOrEditContactor({ maps, callback }, { call, put }) {
      const resp = yield call(service.saveOrEditContactor, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *delUserContacto({ maps, callback }, { call, put }) {
      const resp = yield call(service.delUserContacto, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *getLoanSerNo({ maps, callback }, { call, put }) {
      const resp = yield call(service.getLoanSerNo, { ...maps });
      if (resp.code === 1) {
        if (callback && typeof callback === 'function') {
          if (resp.result) {
            yield put({
              type: 'setSerno',
              serno: resp.result,
            });
            callback(resp.result);
          } else {
            yield put({
              type: 'showMsg',
              msg: resp.msg ? resp.msg : '获取流水号为空，稍后重试',
            });
          }
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '获取流水号出错，稍后重试',
        });
      }
    },

    *checkApply({ maps }, { call, put }) {
      const resp = yield call(service.checkApply, { ...maps });
      if (resp.code === 1) {
        router.replace('/ApplyResult');
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '提交预审出错，稍后重试',
        });
      }
    },

    *userRegisterOnaxSign({ maps, callback }, { call, put }) {
      const { user_id } = maps;
      const resp = yield call(service.userRegisterOnaxSign, { user_id });
      if (resp.code === 1 || resp.code === 9) {
        //开户成功，发送验证码
        window.g_app._store.dispatch({
          type: 'index/sendSMSOnSXS',
          maps,
          callback,
        });
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '注册签署出错，稍后重试',
        });
      }
    },

    *sendSMSOnSXS({ maps, callback }, { call, put }) {
      const resp = yield call(service.sendSMSOnSXS, { ...maps });
      if (resp.code === 1) {
        //开户成功，发送验证码
        callback(resp);
      } else if (resp.code === 7) {
        resp.code = 1;
        yield put({
          type: 'setIsGetAuth',
          isGetAuth: true,
        });
        yield put({
          type: 'showMsg',
          msg: '签署已授权，请直接提交即可，无需输入短信验证码',
        });
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '获取签署验证码出错，稍后重试',
        });
      }
    },

    *confirmSMS({ extraMaps, callback }, { call, put }) {
      const resp = yield call(service.confirmSMS, { ...extraMaps });
      if (resp.code === 1) {
        //安心签确认授权短信验证码后，安心签电子签署
        window.g_app._store.dispatch({
          type: 'index/batchSign',
          extraMaps,
          callback,
        });
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '签署确认授权短信验证码错误，稍后重试',
        });
      }
    },

    *batchSign({ extraMaps, callback }, { call, put }) {
      const resp = yield call(service.batchSign, { ...extraMaps });
      if (resp.code === 1) {
        // 签署成功
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '签署出错，稍后重试',
        });
      }
    },

    // 查询电子保单
    *getElecPolicy({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.getElecPolicy, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '保单查询失败，请联系客户经理',
        });
      }
    },

    // 获取电子协议
    *downloadPro({ maps, callback }, { call, put }) {
      const resp = yield call(weChatService.downloadPro, { ...maps });
      if (resp.code === 0) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '协议查询失败，请联系客户经理',
        });
      }
    },

    // 根据code获取微信openid和access_token
    *getWxUserInfo({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.getWxUserInfo, { ...maps });
      if (resp.code === 0) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '登录异常，请退出页面重试',
        });
      }
    },

    // 登录，发送验证码
    *sendCodeCheckAuthor({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.sendCodeCheckAuthor, { ...maps });
      if (resp.code === 0) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '获取验证码异常，请稍后重试',
        });
      }
    },

    // 验证手机验证码
    *checkSmsCode({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.checkSmsCode, { ...maps });
      if (resp.code === 0) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '登录异常，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）查询个人中心信息
    *personalInfo({ maps, callback }, { call, put, select }) {
      const resp = yield call(accountService.personalInfo, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
        const userInfo = yield select(state => state.account.userInfo);
        console.log(userInfo);
        let u = Object.assign(userInfo, resp.data);
        yield put({
          type: 'setUserInfo',
          userInfo: u,
        });
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '发生异常，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）查询银行卡列表
    *bankCardList({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.bankCardList, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
        yield put({
          type: 'setBankCardList',
          bankCardList: resp.data,
        });
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询银行卡列表发生异常，请稍后重试',
        });
      }
    },

    // 银行卡绑定--查询开户行信息
    *getBankInfo({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.getBankInfo, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询开户行信息发生异常，请稍后重试',
        });
      }
    },

    // 银行卡绑定--查询开户行信息
    *sendSignMsg({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.sendSignMsg, { ...maps });
      if (resp.code === 0) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '发送签约短信发生异常，请稍后重试',
        });
      }
    },

    // 银行卡绑定--签约
    *sign({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.sign, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '银行卡签约异常，请稍后重试',
        });
      }
    },

    // 哈密连连支付短信 , 后台的code这里是相反的
    *hamiTieCard({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.hamiTieCard, { ...maps });
      if (resp.code === 1) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '发送签约短信发生异常，请稍后重试',
        });
      }
    },

    // 哈密连连支付短信--签约, 后台的code这里是相反的
    *hamiCardSmsCheck({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.hamiCardSmsCheck, { ...maps });
      if (resp.code === 1) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '银行卡签约异常，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）查询账户明细列表
    *accountDetail({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.accountDetail, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
        yield put({
          type: 'setAccountDetail',
          accountDetail: resp.data,
        });
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询账户明细列表异常，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）查询可提现余额
    *getAvaWithdrawalBalance({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.getAvaWithdrawalBalance, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询可提现余额异常，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）发起提现
    *balanceWithdrawal({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.balanceWithdrawal, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '发起提现异常，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）发送绑定提现卡验证短信
    *sendCashMsg({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.sendCashMsg, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '获取提现卡验证短信，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）校验绑定提现卡短信
    *checkCashMsg({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.checkCashMsg, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '校验绑定提现卡短信失败，请稍后重试',
        });
      }
    },

    // （登录）微信解绑
    *unbindWechat({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.unbindWechat, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '微信解绑失败，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）查询收银台信息
    *cashierInfo({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.cashierInfo, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询收银台信息失败，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）钱包余额充值
    *balanceCharge({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.balanceCharge, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '钱包余额充值失败，请稍后重试',
        });
      }
    },

    // （个人中心&钱包）查询交易详情
    *transactionDetail({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.transactionDetail, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询交易详情失败，请稍后重试',
        });
      }
    },

    // （投保详情）查询我的投保列表
    *myInsureList({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.myInsureList, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询投保列表失败，请稍后重试',
        });
      }
    },

    // （投保详情）查询仍需还款列表
    *repaymentList({ maps, callback }, { call, put }) {
      console.log('resp---');
      const resp = yield call(accountService.repaymentList, { ...maps });
      console.log(resp);
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询还款列表失败，请稍后重试',
        });
      }
    },

    // （投保详情）查询投保详情
    *detail({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.detail, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询投保详情失败，请稍后重试',
        });
      }
    },

    // （投保详情）查询审批进度
    *approvalProgress({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.approvalProgress, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询审批进度，请稍后重试',
        });
      }
    },

    // 更新客户手机号
    *updateCusPhone({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.updateCusPhone, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '更换手机号失败，请稍后重试',
        });
      }
    },

    // 仍需还款页面-立即还款：立即扣款，发起扣款指令，如威海+烟台，无需传银行卡；另外就是在收银台页面调用。
    *repaymentSelf({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.repaymentSelf, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '操作失败，请稍后重试',
        });
      }
    },

    // （投保详情）查询还款及缴费明细列表
    *repaymentPaymentDetaliList({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.repaymentPaymentDetaliList, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询还款及缴费明细失败，请稍后重试',
        });
      }
    },

    // （投保详情）查询单期缴费明细
    *paymentDetail({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.paymentDetail, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询单期缴费明细失败，请稍后重试',
        });
      }
    },

    // （投保详情）查询单期还款明细
    *repaymentDetail({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.repaymentDetail, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询单期还款明细失败，请稍后重试',
        });
      }
    },

    // 更改客户卡号
    *updateCustomerAccount({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.updateCustomerAccount, { ...maps });
      callback(resp);
    },

    // 提前还款试算
    *repaymentTrial({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.repaymentTrial, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '提前还款试算失败，请稍后重试',
        });
      }
    },

    // 提前还款
    *repaymentSettle({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.repaymentSettle, { ...maps });
      if (resp.code === 0) {
        callback(resp.data);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '提前结清失败，请稍后重试',
        });
      }
    },

    // 发送验证码（更换手机号时用）
    *sendCodeCheck({ maps, callback }, { call, put }) {
      const resp = yield call(accountService.sendCodeCheck, { ...maps });
      if (resp.code === 0) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '获取验证码异常，请稍后重试',
        });
      }
    },
  },

  subscriptions: {},
};
