// import fetch from 'dva/fetch';
import router from 'umi/router';
import * as utils from './common';
import md5 from 'md5-js';
const fetch = require('dva').fetch;

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default async function request(url, method = 'post', data) {
  const { file, base64Str, certFront, certBack } = data;
  const options = {
    method: method,
    headers: {
      // 'Content-Type': file ? "multipart/form-data" : 'application/json',
      version: '20200720',
      // token: utils.getUserInfo() ? utils.getUserInfo().token : '',
      // cus_id: utils.getUserInfo() ? utils.getUserInfo().user_id : '',
      openid: localStorage.openid ? localStorage.openid : '',
      token: localStorage.token ? localStorage.token : '',
      jumpToken: localStorage.jumpToken ? localStorage.jumpToken : '',
      credit_coordinate: localStorage.credit_coordinate ? localStorage.credit_coordinate : '',
    },
    credentials: 'include', // 是否携带cookie，默认为omit,不携带; same-origi,同源携带; include,同源跨域都携带
  };
  if (method === 'get') {
    url += '?' + parseQuery(data);
  } else {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(data);
  }
  if (url.indexOf('hami') > -1) {
    url += '?' + parseQuery(data);
  }
  if (!process.env.prod) {
    console.group('request');
    console.log('options.headers', JSON.stringify(options.headers));
    console.log('options.body', JSON.stringify(options.body));
    console.log('url', url);
  }
  const response = await fetch(url, options);
  checkStatus(response);
  const result = await response.json();
  if (!process.env.prod) {
    console.log('result', JSON.stringify(result));
    console.groupEnd();
  }
  // 登录失效
  if (result && result.code === 3002) {
    // window.g_app._store.dispatch({
    //   type: 'index/showGlobalModal',
    //   msg: result.msg
    // });
    localStorage.clear();
    router.push('/');
  }
  return result;
}

const parseQuery = obj => {
  let str = '';
  for (let key in obj) {
    const value = typeof obj[key] !== 'string' ? JSON.stringify(obj[key]) : obj[key];
    str += '&' + key + '=' + value;
  }
  return str.substr(1);
};
