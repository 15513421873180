import React, { useState, useEffect } from 'react';
import { fetchMock } from '../../../mock/fetchMock';
import { List, Modal, Button, WhiteSpace } from 'antd-mobile';
import { connect } from 'dva';
import * as utils from '../utils/common';
import router from 'umi/router';
import acccountCss from '../account/account.css';
import signCss from '../sign/sign.css';
import ModalIframe from '../../component/ModalIframe';

const Item = List.Item;
const Brief = Item.Brief;

function ElectronicAgreementList(props) {
  const [isShowContract, setIsShowContract] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [paperUrl, setPaperUrl] = useState('');
  const [counter, setCounter] = useState();
  const [isEndTime, setIsEndTime] = useState(false);
  const [btnText, setBtnText] = useState('我已阅读并同意（5s），去签署');
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    document.title = '待签署合同列表';
    // fetchMock('/api/getContracts', {})
    //   .then(response => response.json())
    //   .then(res => {
    //     console.log(res);
    //     props.dispatch({
    //       type: 'sign/setContracts',
    //       contracts: res.data,
    //     });
    //   });
    // if (!props.waitSignList || props.waitSignList.length < 1) {
    //   const maps = {
    //     cusName: localStorage.cusName,
    //     certCode: localStorage.certCode,
    //   };
    //   props.dispatch({
    //     type: 'sign/getSignSernoTaskList',
    //     maps,
    //     callback: r => {
    //       setUpdate(props.waitSignList.length);
    //     },
    //   });
    // }
  }, []);

  // const { contracts } = this.props;
  // const { isShowContract, paperUrl } = this.state;

  function btnTimeCounter() {
    var c = 0;
    let e = Number(process.env.countdown);
    let t = setInterval(() => {
      if (e > c) {
        setBtnText('我已阅读并同意' + '（' + (e - c) + 's），去签署');
        setIsEndTime(false);
      } else {
        setBtnText('我已阅读并同意，去签署');
        clearInterval(t);
        setIsEndTime(true);
      }
      c++;
    }, 1000);
    setCounter(t);
  }
  return (
    <div
      className={acccountCss.container}
      style={{ padding: '20px 0px', fontFamily: 'PingFang SC' }}
    >
      {props.waitSignList && props.waitSignList.length > 0 ? (
        <List renderHeader={() => '签署列表，请选择签署任务进行签署'} className="my-list">
          {props.waitSignList.map((d, index) => {
            return (
              <Item
                key={index}
                arrow="horizontal"
                onClick={() => {
                  if (!d.serno || !d.phone) {
                    props.dispatch({
                      type: 'index/showGlobalModal',
                      msg: '签署数据缺失，请联系客户经理',
                    });
                    return;
                  }
                  props.dispatch({
                    type: 'sign/setCurrentWaitSign',
                    currentWaitSign: JSON.stringify(d),
                  });
                  setIsShowModal(true);
                  btnTimeCounter();
                }}
              >
                {d.sign_name}
                <Brief>{d.serno}</Brief>
                <Brief>{d.apply_date}</Brief>
              </Item>
            );
          })}
        </List>
      ) : (
        <img
          style={{
            position: 'relative',
            left: 'calc((100% - 160px) / 2)',
            top: '150px',
          }}
          width="160px"
          height="160px"
          src={require('../../assets/account/icon_no message@2x.png')}
        ></img>
      )}
      <ModalIframe
        isShowContract={isShowContract}
        paperUrl={paperUrl}
        callback={() => {
          setIsShowContract(false);
        }}
      />

      <Modal
        visible={isShowModal}
        transparent
        maskClosable={false}
        title="投保意愿确认事项告知"
        wrapProps={{ onTouchStart: utils.onWrapTouchStart }}
        style={{ width: '90vw' }}
        afterClose={() => {
          clearInterval(counter);
        }}
      >
        <div style={{ textAlign: 'left', textIndent: '2rem' }}>
          您将进入《华安财产保险股份有限公司货币债务履约保证保险条款》保险的投保环节。
          <WhiteSpace />
          1、请您在投保单首部阅读本公司最近季度的偿付能力信息及风险综合评级结果，以确认我司偿付能力符合相关监管要求，请您可以安心投保。
          <WhiteSpace />
          2、请您务必仔细阅读《华安财产保险股份有限公司货币债务履约保证保险条款》的内容，尤其是其中关于保险责任、责任免除、投保人义务、保险人追偿权等重点提示您的内容。同时请您务必如实填写个人信息、贷款申请信息、投保信息，否则您的权益可能因提供错误或不真实的信息而无法实现。
          <WhiteSpace size="xl" />
          <div style={{ marginLeft: '0 20px', textAlign: 'center' }}>
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                padding: '0 10px',
              }}
            >
              <Button
                disabled={!isEndTime}
                type="primary"
                style={{ fontSize: '15px', paddingRight: '30px' }}
                onClick={() => {
                  setIsShowModal(false);
                  let i = JSON.parse(props.currentWaitSign);
                  router.replace({
                    pathname: '/Sign',
                    query: {
                      serno: i.serno,
                      signType: '11', //以后也是从这个item中取，现在先写死
                      telphone: i.phone,
                    },
                  });
                }}
              >
                {btnText}
              </Button>
              <WhiteSpace size="lg" />
              <div
                className={signCss.btn1}
                onClick={() => {
                  setIsShowModal(false);
                }}
              >
                暂不签署
              </div>
            </div>
            <WhiteSpace size="lg" />
          </div>
        </div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state.sign,
  };
}

export default connect(mapStateToProps)(ElectronicAgreementList);
