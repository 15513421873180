import { Modal, Toast } from 'antd-mobile';
// import fetch from 'dva/fetch';
import * as signService from '../pages/services/sign';
import router from 'umi/router';
import { startRecord } from '../pages/utils/situUtils';

export default {
  state: {
    title: '华安信保',
    manualLoading: false, //手动
    globalModalMsg: '',
    globalVisible: false,
    userInfo: '',
    accessToken: '',
    tbdFlag:
      '感谢您选择华安保险，我司已受理您的投保申请。我司出单后向您手机发送保单查询链接，您也可以在本微信公众号对话框底部点击菜单“信保助手->查询保单”查询电子保单或者联系客户经理寄送纸质保单。',
    contracts: [],
    waitSignList: [], //待签署（流水号）任务列表
    currentWaitSign: {},
  },

  reducers: {
    setCurrentWaitSign(state, { currentWaitSign }) {
      return { ...state, currentWaitSign };
    },

    setTbdFlag(state, { tbdFlag }) {
      return { ...state, tbdFlag };
    },

    setAccessToken(state, { accessToken }) {
      localStorage.setItem('accessToken', accessToken);
      return { ...state, accessToken };
    },

    setWaitSignList(state, { waitSignList }) {
      return { ...state, waitSignList };
    },

    resetState(state, {}) {
      localStorage.clear();
      // console.assert(process.env.prod, 'resetState');
      return { ...state, userInfo: '', anjuCSignContractInfo: '', serno: '', isGetAuth: false };
    },

    setManualLoading(state, { manualLoading }) {
      return { ...state, manualLoading };
    },

    setContracts(state, { contracts }) {
      return { ...state, contracts };
    },

    setUserInfo(state, { userInfo }) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      return { ...state, userInfo };
    },

    showGlobalModal(state, { msg }) {
      return { ...state, globalVisible: true, globalModalMsg: msg };
    },

    hideGlobalModal(state) {
      return { ...state, globalVisible: false };
    },

    showMsg(state, { msg }) {
      const alertInstance = Modal.alert('提示', msg, [
        { text: '确定', onPress: () => alertInstance.close, style: 'default' },
      ]);
      return { ...state };
    },

    startSitu(state) {
      startRecord()
        .then(r => {
          if (!process.env.prod) {
            Toast.success('测试环境才有的提示：启动可回溯，sessionStorage.situ');
          }
        })
        .catch(e => {
          console.log('启动可回溯失败');
        });
      return { ...state };
    },
  },

  effects: {
    // 根据姓名和身份证号获取待签署（流水号）任务列表
    *getSignSernoTaskList({ maps, callback }, { call, put }) {
      const resp = yield call(signService.getSignSernoTaskList, { ...maps });
      localStorage.removeItem('biz_id');
      localStorage.removeItem('cusName');
      localStorage.removeItem('certCode');
      if (resp.code === 0) {
        if (resp.data && resp.data.waitSignList && resp.data.waitSignList.length > 0) {
          if (resp.data.accessToken) {
            callback(resp.data);
            yield put({
              type: 'setAccessToken',
              accessToken: resp.data.accessToken,
            });
            yield put({
              type: 'setWaitSignList',
              waitSignList: resp.data.waitSignList,
            });
          } else {
            yield put({
              type: 'showMsg',
              msg: '查无用户（accessToken）',
            });
          }
        } else {
          yield put({
            type: 'showMsg',
            msg: '暂无需要签署的合同',
          });
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '查询待签署任务失败，请联系客户经理',
        });
      }
    },

    // 查询待签署合同列表
    *getSignContractList({ maps, callback }, { call, put }) {
      const resp = yield call(signService.getSignContractList, { ...maps });
      if (resp.code === 0) {
        if (resp.data && resp.data.length > 0) {
          callback(resp.data);
        } else {
          yield put({
            type: 'showMsg',
            msg: resp.msg ? resp.msg : '查询待签署合同列表异常（空），请稍后重试',
          });
          router.go(-1);
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '查询待签署合同列表出错，稍后重试',
        });
      }
    },

    // 发送电子签署短信
    *sendSignSms({ maps, callback }, { call, put }) {
      Toast.loading('发送短信中', 100);
      const resp = yield call(signService.sendSignSms, { ...maps });
      Toast.hide();
      if (resp.code === 0) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '获取签署短信出错，稍后重试',
        });
      }
    },

    // 确认短信并进行签署
    *confirmSmsAndSign({ maps, callback }, { call, put }) {
      Toast.loading('签署中', 100);
      const resp = yield call(signService.confirmSmsAndSign, { ...maps });
      Toast.hide();
      if (resp.code === 0) {
        if (resp.data.tbdFlag) {
          yield put({
            type: 'setTbdFlag',
            tbdFlag: resp.data.tbdFlag,
          });
        }
        Toast.success('签署成功', 1);
        callback(resp.data);
      } else {
        // 重新开启思图录屏
        yield put({
          type: 'startSitu',
        });
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '短信签署出错，稍后重试',
        });
      }
    },

    // 这个服务的code 1 才是成功的
    *h5FaceToken({ maps, callback }, { call, put }) {
      const resp = yield call(signService.h5FaceToken, { ...maps });
      if (resp.code === 1) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '获取FaceToken出错，稍后重试',
        });
      }
    },

    // 这个服务的code 1 才是成功的
    *h5getResult({ maps, callback }, { call, put }) {
      const resp = yield call(signService.h5getResult, { ...maps });
      if (resp.code === 1) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '获取Face结果出错，稍后重试',
        });
      }
    },

    // 保存录屏任务记录
    *saveScreenInfo({ maps, callback }, { call, put }) {
      const resp = yield call(signService.saveScreenInfo, { ...maps });
      if (resp.code === 1) {
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.message ? resp.message : '保存record信息失败，稍后重试',
        });
      }
    },
  },

  subscriptions: {},
};
