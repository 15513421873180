import React, { Component } from 'react';
import styles from './index.css';
import { Modal } from 'antd-mobile'; //NavBar, Icon,
import { Spin, Result, Button } from 'antd';
import { connect } from 'dva';
import rStyles from '../pages/index.less';
import router from 'umi/router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import VConsole from 'vconsole';

// var fundebug = require("fundebug-javascript");
// fundebug.apikey = "eaf8ad74a9c6f0cb0beb316964767c047270ae64350cfefee4ce563d1d488f22";

class BasicLayout extends Component {
  // function BasicLayout(props) {
  // console.log('BasicLayout',props)

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    // if (!process.env.prod) {
    //   var vConsole = new VConsole();
    // }

    if (process.env.PRD_NAME === 'cloudAccount') {
      Sentry.init({
        // dsn:'http://cb2943957e3e402fbd257da71887c479@10.1.110.119:9000/10',
        dsn: 'https://cb2943957e3e402fbd257da71887c479@test-sentry.sinosafe.com.cn/10',
        integrations: [new Integrations.BrowserTracing()],
        release: process.env.RELEASE_VERSION,
        environment: process.env.RELEASE_EVN_NAME,
        tracesSampleRate: 1.0,
      });
    }

    if (process.env.PRD_NAME === 'signContract') {
      Sentry.init({
        // dsn:'http://f5251b08c8ad467590f4756dc1fbc7ed@10.1.110.119:9000/13',
        dsn: 'https://f5251b08c8ad467590f4756dc1fbc7ed@test-sentry.sinosafe.com.cn/13',
        integrations: [new Integrations.BrowserTracing()],
        release: process.env.RELEASE_VERSION,
        environment: process.env.RELEASE_EVN_NAME,
        tracesSampleRate: 1.0,
      });
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // 将component中的报错发送到Fundebug
    // fundebug.notifyError(error, {
    //   metaData: {
    //     info: info
    //   }
    // });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="warning"
          title="非常抱歉，页面出现异常，请退出重试"
          extra={
            <Button
              type="primary"
              onClick={() => {
                this.setState(
                  {
                    hasError: false,
                  },
                  () => {
                    localStorage.clear();
                    router.replace('/');
                  },
                );
              }}
            >
              返回首页
            </Button>
          }
        />
      );
    }
    return (
      <Spin spinning={this.props.loading.global || this.props.manualLoading} tip="请稍后...">
        <div className={styles.normal}>{this.props.children}</div>
        <Modal
          visible={this.props.globalVisible}
          transparent
          maskClosable={false}
          title="提示"
          className={rStyles.customSelect}
          footer={[
            {
              text: '确定',
              onPress: value => {
                console.log('index/hideGlobalModal');
                window.g_app._store.dispatch({
                  type: 'index/hideGlobalModal',
                });
              },
            },
          ]}
        >
          {this.props.globalModalMsg}
        </Modal>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading,
    ...state.index,
  };
}

// 添加dva-loading
export default connect(mapStateToProps)(BasicLayout);
