// 验证码 6位
export const code = /^\d{6}$/;
export const code_rules = [
  {
    required: true,
    message: '验证码不能为空',
  },
  {
    pattern: new RegExp(code),
    message: '请输入6位验证码',
  },
];

// 验证码 4位
export const code4 = /^\d{4}$/;
export const code4_rules = [
  {
    required: true,
    message: '验证码不能为空',
  },
  {
    pattern: new RegExp(code4),
    message: '请输入4位验证码',
  },
];

// 手机号码
export const mobile = '^[1]{1}[3,4,5,6,7,8,9]{1}[0-9]{9}$';
export const mobile_rules = [
  {
    required: true,
    message: '手机号码不能为空',
  },
  {
    pattern: new RegExp(mobile),
    message: '请输入正确手机号码',
  },
];

// 姓名
export const name = '^[\u4E00-\u9FA5·•]+$';
export const name_rules = [
  {
    required: true,
    message: '姓名不能为空',
  },
  {
    pattern: new RegExp(name),
    message: '请输入正确姓名',
  },
];

// 邮箱
export const mail = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
export const mail_rules = [
  {
    required: true,
    message: '邮箱不能为空',
  },
  {
    pattern: new RegExp(mail),
    message: '请输入正确邮箱',
  },
];

// 班级名称
export const class_rules = [
  {
    required: true,
    message: '班级不能为空',
  },
];

// 班级人数
export const num = /^\+?[1-9][0-9]*$/;
export const num_rules = [
  {
    required: true,
    message: '班级人数不能为空',
  },
  {
    pattern: new RegExp(num),
    message: '请输入正确的班级人数',
  },
];

// 福建班级人数
export const numF = /^([1-9]|[1-9]\d|1\d{2}|199)$/;
export const numF_rules = [
  {
    required: true,
    message: '班级人数不能为空',
  },
  {
    pattern: new RegExp(numF),
    message: '请输入0至200之间的人数',
  },
];

// 班级数
export const classNum = /(^[1-4][0-9]$)|(^[1-9]$)/;
export const classNum_rules = [
  {
    required: true,
    message: '班级数不能为空',
  },
  {
    pattern: new RegExp(classNum),
    message: '请输入0至50之间的班级数',
  },
];

// 身份证
export const idcard = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
export const idcard_rules = [
  {
    required: true,
    message: '身份证不能为空',
  },
  {
    pattern: new RegExp(idcard),
    message: '请输入正确的身份证',
  },
];

// 银行卡
export const bankCard = /^([1-9]{1})(\d{15}|\d{18})$/;
export const bankCard_rules = [
  {
    required: true,
    message: '银行卡号码不能为空',
  },
  {
    pattern: new RegExp(bankCard),
    message: '请输入正确的银行卡号码',
  },
];

// 固定电话、手机号码
// export const call =/^(((d{2}-)?0d{2,3}-d{7,8})|((+d{2}-)?(d{2,3}-)?([1][3,4,5,7,8][0-9]d{8})))$/;
export const call = /^(1\d{10}|\d{3,4}-\d{7,8}-\d{1,8}|\d{3,4}-\d{7,8})$/;
// export const call = "(1\\d{10})|(\\d{3,4}-\\d{7,8}-\\d{1,8}|(\\d{3,4}-\\d{7,8}))";
export const call_rules = [
  {
    required: true,
    message: '号码不能为空',
  },
  {
    pattern: new RegExp(call),
    message: '请输入正确的号码',
  },
];

// 贷款金额 5万-50万 精确到千位
// export const loanMoney = /^([5-9][0-9]0{3}|[1-4]\d{2}0{3}|500000)$/;
export const loanMoney = /^([5-9](.(\d){1})?|[1-4]\d{1}(.(\d){1})?|50)$/;
export const loanMoney_rules = [
  {
    required: true,
    message: '贷款金额不能为空',
  },
  {
    pattern: new RegExp(loanMoney),
    message: '贷款金额为5万-50万，精确到千元(一位小数点)',
  },
];

// 信用卡有效期校验 (0[1-9]|1[0-2])[0-9]{2}
export const paymentCardDate = /^((0[1-9]|1[0-2])[0-9]{2})$/;
export const paymentCardDate_rules = [
  {
    required: true,
    message: '卡片有效期格式不能为空',
  },
  {
    pattern: new RegExp(paymentCardDate),
    message: '卡片有效期格式错误，如您的卡片有效期是10/30，请输入1030。',
  },
];

// 地址 /^[\\u4E00-\\u9FA5A-Za-z0-9-()（）]+$/;
// '^[\\u4E00-\\u9FA5A-Za-z0-9\\*]+$'
export const address = '^[\\u4E00-\\u9FA5A-Za-z0-9-()（）]+$';
export const address_rules = [
  {
    required: true,
    message: '地址不能为空',
  },
  {
    pattern: new RegExp(address),
    message: '地址仅支持输入中英文和括号、-',
  },
];

// 金额
export const money = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;

// 共用，不为空的判断
export const notNull_rules = [
  {
    required: true,
    message: '不能为空',
  },
];
