import request from '../utils/requestAccount';

// 测试服务器：
// const prefix = process.env.url_prefix;
const prefix = process.env.weChat_url_prefix;
const app_prefix = process.env.contractPrefix;

// 微信签名串
export function getWeixinSignature(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'wenxin/getWeixinSignature'
      : '/sign_api/wenxin/getWeixinSignature',
    'post',
    props,
  );
}

// 根据code，判断用户是否已经绑定和关注服务号
export function getWxUserInfo(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'wenxin/getWxUserInfo'
      : '/wechat_api/wenxin/getWxUserInfo',
    'post',
    props,
  );
}

// （登录）发送验证码
export function sendCodeCheckAuthor(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'wenxin/sendCodeCheckAuthor'
      : '/wechat_api/wenxin/sendCodeCheckAuthor',
    'post',
    props,
  );
}

// （登录）验证手机验证码
export function checkSmsCode(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'wenxin/checkSmsCode'
      : '/wechat_api/wenxin/checkSmsCode',
    'post',
    props,
  );
}

// （登录）微信解绑
export function unbindWechat(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'wenxin/unbindWechat'
      : '/wechat_api/wenxin/unbindWechat',
    'post',
    props,
  );
}

// （个人中心&钱包）查询个人中心信息
export function personalInfo(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/personalInfo'
      : '/wechat_api/personal/wallet/personalInfo',
    'post',
    props,
  );
}

// （个人中心&钱包）查询银行卡列表
export function bankCardList(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/bankCardList'
      : '/wechat_api/personal/wallet/bankCardList',
    'post',
    props,
  );
}

// 查询电子保单
export function getElecPolicy(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/getElecPolicy'
      : '/wechat_api/insure/getElecPolicy',
    'post',
    props,
  );
}

// 银行卡绑定--查询开户行信息
export function getBankInfo(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/getBankInfo'
      : '/wechat_api/personal/wallet/getBankInfo',
    'post',
    props,
  );
}

// 银行卡绑定--发送签约短信
export function sendSignMsg(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/sendSignMsg'
      : '/wechat_api/personal/wallet/sendSignMsg',
    'post',
    props,
  );
}

// 银行卡绑定--签约
export function sign(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/sign'
      : '/wechat_api/personal/wallet/sign',
    'post',
    props,
  );
}

// 哈密连连支付发送短信
export function hamiTieCard(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? app_prefix + 'external/hami/hamiTieCard'
      : '/wechat_api/v2/external/hami/hamiTieCard',
    'post',
    props,
  );
}

// 哈密连连支付绑卡短信验证
export function hamiCardSmsCheck(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? app_prefix + 'external/hami/hamiCardSmsCheck'
      : '/wechat_api/v2/external/hami/hamiCardSmsCheck',
    'post',
    props,
  );
}

// （个人中心&钱包）查询账户明细列表
export function accountDetail(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/accountDetail'
      : '/wechat_api/personal/wallet/accountDetail',
    'post',
    props,
  );
}

// （个人中心&钱包）查询可提现余额
export function getAvaWithdrawalBalance(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/getAvaWithdrawalBalance'
      : '/wechat_api/personal/wallet/getAvaWithdrawalBalance',
    'post',
    props,
  );
}

// （个人中心&钱包）发起提现
export function balanceWithdrawal(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/balanceWithdrawal'
      : '/wechat_api/personal/wallet/balanceWithdrawal',
    'post',
    props,
  );
}

// （个人中心&钱包）发送绑定提现卡验证短信，当银行卡的 bindStatus为 N的时候 才需要发验证码绑卡
export function sendCashMsg(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/sendCashMsg'
      : '/wechat_api/personal/wallet/sendCashMsg',
    'post',
    props,
  );
}

// （个人中心&钱包）校验绑定提现卡短信
export function checkCashMsg(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/checkCashMsg'
      : '/wechat_api/personal/wallet/checkCashMsg',
    'post',
    props,
  );
}

// （个人中心&钱包）查询收银台信息
export function cashierInfo(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/cashierInfo'
      : '/wechat_api/personal/wallet/cashierInfo',
    'post',
    props,
  );
}

// （个人中心&钱包）钱包余额充值
export function balanceCharge(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/balanceCharge'
      : '/wechat_api/personal/wallet/balanceCharge',
    'post',
    props,
  );
}

// （个人中心&钱包）查询交易详情
export function transactionDetail(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'personal/wallet/transactionDetail'
      : '/wechat_api/personal/wallet/transactionDetail',
    'post',
    props,
  );
}

// （投保详情）查询我的投保列表
export function myInsureList(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/myInsureList'
      : '/wechat_api/insure/myInsureList',
    'post',
    props,
  );
}

//（投保详情）查询仍需还款列表
export function repaymentList(props) {
  console.log('resp---0');
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/repaymentList'
      : '/wechat_api/insure/repaymentList',
    'post',
    props,
  );
}

//（投保详情）查询投保详情
export function detail(props) {
  return request(
    process.env.NODE_ENV === 'production' ? prefix + 'insure/detail' : '/wechat_api/insure/detail',
    'post',
    props,
  );
}

//（投保详情）查询审批进度
export function approvalProgress(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/approvalProgress'
      : '/wechat_api/insure/approvalProgress',
    'post',
    props,
  );
}

// 更新客户手机号
export function updateCusPhone(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/updateCusPhone'
      : '/wechat_api/insure/updateCusPhone',
    'post',
    props,
  );
}

// 仍需还款页面-立即还款
export function repaymentSelf(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/repaymentSelf'
      : '/wechat_api/insure/repaymentSelf',
    'post',
    props,
  );
}

// （投保详情）查询还款及缴费明细列表
export function repaymentPaymentDetaliList(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/repaymentPaymentDetaliList'
      : '/wechat_api/insure/repaymentPaymentDetaliList',
    'post',
    props,
  );
}

//（投保详情）查询单期还款明细
export function repaymentDetail(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/repaymentDetail'
      : '/wechat_api/insure/repaymentDetail',
    'post',
    props,
  );
}

//（投保详情）查询单期缴费明细
export function paymentDetail(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/paymentDetail'
      : '/wechat_api/insure/paymentDetail',
    'post',
    props,
  );
}

// 更改客户银行卡号
export function updateCustomerAccount(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/updateCustomerAccount'
      : '/wechat_api/insure/updateCustomerAccount',
    'post',
    props,
  );
}

// 提前还款试算
export function repaymentTrial(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/repaymentTrial'
      : '/wechat_api/insure/repaymentTrial',
    'post',
    props,
  );
}

//提前还款
export function repaymentSettle(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/repaymentSettle'
      : '/wechat_api/insure/repaymentSettle',
    'post',
    props,
  );
}

// 获取电子保单
export function geturl(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'lstquery/geturl'
      : '/wechat_api/lstquery/geturl',
    'post',
    props,
  );
}

// 获取电子协议
export function downloadPro(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'lstquery/downloadPro'
      : '/wechat_api/lstquery/downloadPro',
    'post',
    props,
  );
}

// 发送验证码（更换手机号时用）
export function sendCodeCheck(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'insure/sendCodeCheck'
      : '/wechat_api/insure/sendCodeCheck',
    'post',
    props,
  );
}
