import { Toast } from 'antd-mobile';

export function startRecord() {
  console.log('startRecord->window.SituRecorder', window.SituRecorder);
  return new Promise(function(resolve, reject) {
    let initParams = {
      baseUrl: '', // 后端服务器域名，string，可选，默认POC环境，可选
      publicKey: '', // 公钥string，默认POC公钥，可选
      cookieExpires: Date.now() + 3600000, // cookie过期时长，可选
      // timeout: '', // 单次录制超时时间，单位：秒，number，不少于10秒，可选
      videoMaxSize: 5, // 录制视频最大容量限制，单位：M，number，不少于1M，可选
      recordPerTime: 5, // 定时推送录制内容时长，单位：秒，number，不少于5秒，可选，未传时请使用stopRecord方法终止录制
      taskId: localStorage.situTaskId ? localStorage.situTaskId : '', // 任务ID，string，可选
      first: 1,
      env: process.env.situEnv,
    };
    const res = window.SituRecorder.startRecord(initParams);
    console.log('startRecord', res);
    switch (res.code) {
      case 20001:
        if (!process.env.prod) {
          Toast.offline(res.msg ? res.msg : '测试环境才有的提示：可回溯启动异常：20001');
        }
        reject();
        break;
      case 10000:
        if (!process.env.prod) {
          Toast.success('测试环境才有的提示：可回溯初始化成功');
        }
        localStorage.setItem('situTaskId', res.result.taskId);
        resolve(res.result.taskId);
        break;
      case 20005:
        if (!process.env.prod) {
          Toast.offline(res.msg ? res.msg : '测试环境才有的提示：可回溯启动异常：20001');
        }
        reject();
        break;
      default:
        if (!process.env.prod) {
          Toast.offline(res.msg ? res.msg : '测试环境才有的提示：可回溯启动异常：未知');
        }
        reject();
        break;
    }
  });
}

export function stopRecord(currentWaitSign) {
  console.log('stopRecord');
  return new Promise(function(resolve, reject) {
    let a = getNowFormatTime();
    console.log('a--:', a, currentWaitSign);
    // let extraInfo = currentWaitSign;
    let extraInfo = { extra: {} };
    extraInfo.extra.wxface = localStorage.wxface ? localStorage.wxface : '未记录';
    extraInfo.extra.faceidResult = localStorage.faceidResult ? localStorage.faceidResult : '未记录';
    let initParams = {
      env: process.env.situEnv,
      last: 1,
      orderInfo: {
        orderId: currentWaitSign.serno ? currentWaitSign.serno : a,
        insuranceNo: currentWaitSign.serno ? currentWaitSign.serno : a,
        channel: currentWaitSign.belong_br_name ? currentWaitSign.belong_br_name : '--',
        platform: currentWaitSign.insurance_id ? currentWaitSign.insurance_id : '--',
        productName: currentWaitSign.prd_name ? currentWaitSign.prd_name : '--',
        productCode: currentWaitSign.prd_id ? currentWaitSign.prd_id : '--',
        policyHolder: currentWaitSign.cus_name ? currentWaitSign.cus_name : '--',
        account: currentWaitSign.cus_id ? currentWaitSign.cus_id : '--',
        agencyName: currentWaitSign.cus_mgr_mail ? currentWaitSign.cus_mgr_mail : '--',
        agencyCode: currentWaitSign.phone ? currentWaitSign.phone : '--',
        extraInfo: JSON.stringify(extraInfo),
      },
    };
    window.SituRecorder.stopRecord(initParams)
      .then(r => {
        console.log('SituRecorder.r : ', r);
        switch (r.code) {
          case 10000:
            if (!process.env.prod) {
              Toast.success('签署成功：' + currentWaitSign.serno, 2);
            } else {
              Toast.success('签署成功', 2);
            }
            resolve();
            break;
          default:
            if (!process.env.prod) {
              Toast.fail('测试环境才有的提示：可回溯结束异常:' + r.code, 1);
            }
            reject();
            break;
        }
      })
      .catch(e => {
        if (!process.env.prod) {
          Toast.offline(e.msg ? e.msg : '测试环境才有的提示：可回溯结束异常：' + e.code);
        }
        reject();
      });
  });
}

//获取当前时间，格式YYYY-MM-DD HH:mm:ss
function getNowFormatTime() {
  var nowDate = new Date();
  var colon = ':';
  var h = nowDate.getHours();
  var m = nowDate.getMinutes();
  var s = nowDate.getSeconds();
  //补全0，并拼接
  return (
    'xb' +
    colon +
    getNowFormatDay(nowDate) +
    completeDate(h) +
    colon +
    completeDate(m) +
    colon +
    completeDate(s)
  );
}

//获取当前日期，格式YYYY-MM-DD
function getNowFormatDay(nowDate) {
  var char = '-';
  if (nowDate == null) {
    nowDate = new Date();
  }
  var day = nowDate.getDate();
  var month = nowDate.getMonth() + 1; //注意月份需要+1
  var year = nowDate.getFullYear();
  //补全0，并拼接
  return year + char + completeDate(month) + char + completeDate(day);
}

//补全0
function completeDate(value) {
  return value < 10 ? '0' + value : value;
}
