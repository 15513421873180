import { Modal } from 'antd-mobile';
// import fetch from 'dva/fetch';
import * as service from '../pages/services/index';
import router from 'umi/router';

// 黑龙江的models

export default {
  state: {
    title: '宜业宝',
    manualLoading: false, //手动
    globalModalMsg: '',
    globalVisible: false,
    productRateList: [], // 产品期限，费率等信息
    termList: [], // 产品期限，期限
    floorCopList: [], // 楼盘信息
    userInfo: '',
    anjuCSignContractInfo: '',
    serno: '',
    isGetAuth: false, // 安心签是否已授权签署
  },

  reducers: {
    resetState(state, { }) {
      localStorage.clear();
      console.assert(process.env.prod, 'resetState');
      return { ...state, userInfo: '', anjuCSignContractInfo: '', serno: '', isGetAuth: false };
    },

    setManualLoading(state, { manualLoading }) {
      return { ...state, manualLoading };
    },

    setIsGetAuth(state, { isGetAuth }) {
      return { ...state, isGetAuth };
    },

    setSerno(state, { serno }) {
      return { ...state, serno };
    },

    setAnjuCSignContractInfo(state, { anjuCSignContractInfo }) {
      localStorage.setItem('anjuCSignContractInfo', JSON.stringify(anjuCSignContractInfo));
      if (anjuCSignContractInfo && anjuCSignContractInfo.userInfo) {
        localStorage.setItem('role', anjuCSignContractInfo.userInfo.anjucUserType === '0' ? 'A' : 'B');
      }
      return { ...state, anjuCSignContractInfo };
    },

    setUserInfo(state, { userInfo }) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      return { ...state, userInfo };
    },

    showGlobalModal(state, { msg }) {
      console.log('showGlobalModal', msg)
      return { ...state, globalVisible: true, globalModalMsg: msg };
    },

    hideGlobalModal(state) {
      return { ...state, globalVisible: false };
    },

    setIsShowApplyModal(state, { isShowApplyModal }) {
      return { ...state, isShowApplyModal };
    },

    showMsg(state, { msg }) {
      const alertInstance = Modal.alert('提示', msg, [
        { text: '确定', onPress: () => alertInstance.close, style: 'default' },
      ]);
      return { ...state };
    },

    setProductRateList(state, { productRateList }) {
      return { ...state, productRateList };
    },

    setTermList(state, { termList }) {
      localStorage.setItem('termList', JSON.stringify(termList));
      return { ...state, termList };
    },

    setFloorCopList(state, { floorCopList }) {
      return { ...state, floorCopList };
    },
  },

  effects: {
    *getWeixinSignature({ maps, callback }, { call, put }) {
      const resp = yield call(service.getWeixinSignature, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *facePlusOcrAndUploadApi({ maps, callback }, { call, put }) {
      const resp = yield call(service.facePlusOcrAndUploadApi, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *getProductById({ prd_id }, { call, put }) {
      const resp = yield call(service.getProductById, { prd_id });
      if (resp && resp.code === 1) {
        if (resp.result.productRateList) {
          yield put({
            type: 'setProductRateList',
            productRateList: resp.result.productRateList,
          });
          var tList = [];
          for (var index = 0; index < resp.result.productRateList.length; index++) {
            tList.push({
              label: resp.result.productRateList[index].period,
              value: resp.result.productRateList[index].period,
            });
          }
          yield put({
            type: 'setTermList',
            termList: tList,
          });
        } else {
          yield put({
            type: 'showMsg',
            msg: resp.msg ? resp.msg : '获取产品费率信息出错，请联系客户经理',
          });
        }
        if (resp.result.floorCopList) {
          yield put({
            type: 'setFloorCopList',
            floorCopList: resp.result.floorCopList,
          });
        } else {
          yield put({
            type: 'showMsg',
            msg: resp.msg ? resp.msg : '获取楼盘信息出错，请联系客户经理',
          });
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '请求出错，稍后重试',
        });
      }
    },

    *userLogin({ mobile, code, callback }, { call, put }) {
      const resp = yield call(service.userLogin, { mobile, code, terminalType: '10' });
      if (resp && resp.code === 1) {
        yield put({
          type: 'setUserInfo',
          userInfo: resp.result,
        });
        if (callback && typeof callback === 'function') {
          callback(resp.result);
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '请求出错，稍后重试',
        });
      }
    },

    *getAnjuCSignContractList({ maps }, { call, put }) {
      const resp = yield call(service.getAnjuCSignContractList, { ...maps });
      if (resp && resp.code === 1) {
        if (!resp.result.contractList || resp.result.contractList.length < 1) {
          yield put({
            type: 'showMsg',
            msg: '获取签署文件出错，请联系客户经理',
          });
        } else if (!resp.result.userInfo) {
          yield put({
            type: 'showMsg',
            msg: '获取签署客户信息出错，请联系客户经理',
          });
        } else {
          yield put({
            type: 'setAnjuCSignContractInfo',
            anjuCSignContractInfo: resp.result,
          });
          router.push('/SignContainer');
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '请求出错，稍后重试',
        });
      }
    },

    *sendCode({ maps, callback }, { call, put }) {
      const resp = yield call(service.sendCode, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *addAndCheckBankCard({ maps, callback }, { call, put }) {
      const resp = yield call(service.addAndCheckBankCard, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *editProfile({ params, callback }, { call, put }) {
      const resp = yield call(service.editProfile, { ...params });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *addOrEditUserDetail({ maps, callback }, { call, put }) {
      const resp = yield call(service.addOrEditUserDetail, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *saveOrEditContactor({ maps, callback }, { call, put }) {
      const resp = yield call(service.saveOrEditContactor, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *delUserContacto({ maps, callback }, { call, put }) {
      const resp = yield call(service.delUserContacto, { ...maps });
      if (callback && typeof callback === 'function') {
        callback(resp);
      }
    },

    *getLoanSerNo({ maps, callback }, { call, put }) {
      const resp = yield call(service.getLoanSerNo, { ...maps });
      if (resp.code === 1) {
        if (callback && typeof callback === 'function') {
          if (resp.result) {
            yield put({
              type: 'setSerno',
              serno: resp.result,
            });
            callback(resp.result);
          } else {
            yield put({
              type: 'showMsg',
              msg: resp.msg ? resp.msg : '获取流水号为空，稍后重试',
            });
          }
        }
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '获取流水号出错，稍后重试',
        });
      }
    },

    *checkApply({ maps }, { call, put }) {
      const resp = yield call(service.checkApply, { ...maps });
      if (resp.code === 1) {
        router.replace('/ApplyResult');
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '提交预审出错，稍后重试',
        });
      }
    },

    *userRegisterOnaxSign({ maps, callback }, { call, put }) {
      const { user_id } = maps;
      const resp = yield call(service.userRegisterOnaxSign, { user_id });
      if (resp.code === 1 || resp.code === 9) {
        //开户成功，发送验证码
        window.g_app._store.dispatch({
          type: 'index/sendSMSOnSXS',
          maps,
          callback,
        });
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '注册签署出错，稍后重试',
        });
      }
    },

    *sendSMSOnSXS({ maps, callback }, { call, put }) {
      const resp = yield call(service.sendSMSOnSXS, { ...maps });
      if (resp.code === 1) {
        //开户成功，发送验证码
        callback(resp);
      } else if (resp.code === 7) {
        resp.code = 1;
        yield put({
          type: 'setIsGetAuth',
          isGetAuth: true,
        });
        yield put({
          type: 'showMsg',
          msg: '签署已授权，请直接提交即可，无需输入短信验证码',
        });
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '获取签署验证码出错，稍后重试',
        });
      }
    },

    *confirmSMS({ extraMaps, callback }, { call, put }) {
      const resp = yield call(service.confirmSMS, { ...extraMaps });
      if (resp.code === 1) {
        //安心签确认授权短信验证码后，安心签电子签署
        window.g_app._store.dispatch({
          type: 'index/batchSign',
          extraMaps,
          callback,
        });
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '签署确认授权短信验证码错误，稍后重试',
        });
      }
    },

    *batchSign({ extraMaps, callback }, { call, put }) {
      const resp = yield call(service.batchSign, { ...extraMaps });
      if (resp.code === 1) {
        // 签署成功
        callback(resp);
      } else {
        yield put({
          type: 'showMsg',
          msg: resp.msg ? resp.msg : '签署出错，稍后重试',
        });
      }
    },
  },

  subscriptions: {},
};
