import request from '../utils/request';

// 测试服务器：
//https://proxytest.sinosafe.com.cn/stuapp-sit/hastudy-cluster/init/external/preApply/queryHighSchools
const prefix = process.env.url_prefix;

// 微信授权jssdk
export const wechatSignature =
  process.env.NODE_ENV === 'production'
    ? prefix + 'wechat/wechatSignature'
    : '/sinosafe_api/wechat/wechatSignature';

// 微信签名串
//https://proxytest.sinosafe.com.cn/rest-haxb-sit/Rest_HAXB/api/v2/external/microservice/getWeixinSignature
export function getWeixinSignature(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/external/microservice/getWeixinSignature'
      : '/sinosafe_api/v2/external/microservice/getWeixinSignature',
    'get',
    props,
  );
}

//faceid的ocr功能
export function facePlusOcrAndUploadApi(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/external/microservice/facePlusOcrAndUploadApi'
      : '/sinosafe_api/v2/external/microservice/facePlusOcrAndUploadApi',
    'post',
    props,
  );
}

// 查询产品信息
export function getProductById(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/client/consumLoan/getProductById'
      : '/sinosafe_api/v2/client/consumLoan/getProductById',
    'get',
    props,
  );
}

// 用户登录并返回实名结果
export function userLogin(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/client/icity/userLogin'
      : '/sinosafe_api/v2/client/icity/userLogin',
    'get',
    props,
  );
}

// 获取宜业宝待签署列表
export function getAnjuCSignContractList(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/external/anjuc/getAnjuCSignContractList'
      : '/sinosafe_api/v2/external/anjuc/getAnjuCSignContractList',
    'get',
    props,
  );
}

// 获取验证码
export function sendCode(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/client/account/sendCode'
      : '/sinosafe_api/v2/client/account/sendCode',
    'get',
    props,
  );
}

// 银行卡绑定，中金支付
export function addAndCheckBankCard(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/manager/account/addAndCheckBankCard'
      : '/sinosafe_api/v2/manager/account/addAndCheckBankCard',
    'get',
    props,
  );
}

// 保存客户实名认证信息
export function editProfile(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/client/account/editProfile'
      : '/sinosafe_api/v2/client/account/editProfile',
    'post',
    props,
  );
}

// 保存客户申请信息
export function addOrEditUserDetail(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/client/account/addOrEditUserDetail'
      : '/sinosafe_api/v2/client/account/addOrEditUserDetail',
    'post',
    props,
  );
}

// 客户紧急联系人增加或修改
export function saveOrEditContactor(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/manager/consumLoan/saveOrEditContactor'
      : '/sinosafe_api/v2/manager/consumLoan/saveOrEditContactor',
    'post',
    props,
  );
}

// 删除客户紧急联系人
export function delUserContacto(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/manager/consumLoan/delUserContacto'
      : '/sinosafe_api/v2/manager/consumLoan/delUserContacto',
    'post',
    props,
  );
}

// 获取流水号
export function getLoanSerNo(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/client/consumLoan/getLoanSerNo'
      : '/sinosafe_api/v2/client/consumLoan/getLoanSerNo',
    'post',
    props,
  );
}

// 提交预申请
export function checkApply(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/client/consumLoan/checkApply'
      : '/sinosafe_api/v2/client/consumLoan/checkApply',
    'post',
    props,
  );
}

// 安心签发送短信
export function sendSMSOnSXS(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/common/sendSMSOnSXS'
      : '/sinosafe_api/v2/common/sendSMSOnSXS',
    'post',
    props,
  );
}

// 安心签注册
export function userRegisterOnaxSign(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/common/userRegisterOnaxSign'
      : '/sinosafe_api/v2/common/userRegisterOnaxSign',
    'post',
    props,
  );
}

// 安心签确认授权短信验证码
export function confirmSMS(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/common/confirmSMS'
      : '/sinosafe_api/v2/common/confirmSMS',
    'post',
    props,
  );
}

// 安心签电子签署
export function batchSign(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'v2/common/batchSign'
      : '/sinosafe_api/v2/common/batchSign',
    'post',
    props,
  );
}
