import React, { Component } from 'react';
import { Modal } from 'antd-mobile';
import * as utils from '../pages/utils/common';

class ModalIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isShowContract, paperUrl, callback } = this.props;
    return (
      <Modal
        style={{ width: '95%', height: '80%', touchAction: 'none' }}
        animationType="fade"
        platform="ios"
        transparent
        visible={isShowContract}
        maskClosable={true}
        closable={true}
        wrapProps={{ onTouchStart: utils.onWrapTouchStart }}
        onClose={callback}
      >
        <div>
          <iframe
            src={paperUrl}
            id="myiframe"
            frameBorder="0"
            title="myIframe"
            style={{ width: '100%', height: '78vh' }}
            onLoad={() => {
              utils.hideLoading();
            }}
          >
            您的浏览器不支持iframe
          </iframe>
        </div>
      </Modal>
    );
  }
}

export default ModalIframe;
