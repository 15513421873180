import React, { useState, useEffect } from 'react';
import { Result, WhiteSpace, Button, Icon, Toast } from 'antd-mobile';
import { connect } from 'dva';
import router from 'umi/router';
import * as utils from '../utils/common';
import { startRecord, stopRecord } from '../utils/situUtils';

function ResultPage(props) {
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [txtStatus, setTxtStatus] = useState('恭喜，您已完成投保申请！');

  useEffect(() => {
    document.title = '签署成功';
    console.log(props);
    let currentWaitSign = props.currentWaitSign ? JSON.parse(props.currentWaitSign) : {};
    utils.showLoading();
    startRecord()
      .then(r => {
        utils.hideLoading();
        if (!process.env.prod) {
          Toast.success('测试环境才有的提示：启动可回溯，sessionStorage.situ');
        }
        setTimeout(() => {
          situStopRecord(currentWaitSign);
        }, 2500);
      })
      .catch(e => {
        console.log('启动可回溯失败');
        // 即使异常，签署都已经完了，这里做什么都没有意义的了
        situStopRecord(currentWaitSign);
      });
    // 清空待签署的数据
    props.dispatch({
      type: 'sign/setWaitSignList',
      waitSignList: [],
    });
  }, [props, situStopRecord]);

  function situStopRecord(cwt) {
    stopRecord(cwt)
      .then(r => {
        // 无论code 是正常还是异常，签署都已经完了，这里做什么都没有意义的了
      })
      .catch(() => {
        // 即使异常，签署都已经完了，这里做什么都没有意义的了
      })
      .finally(() => {
        // 结束
        setIsBtnDisable(false);
        props.dispatch({
          type: 'sign/setCurrentWaitSign',
          currentWaitSign: {},
        });
      });
  }

  return (
    <div style={{ padding: '20px 0px', fontFamily: 'PingFang SC' }}>
      <Result
        img={
          <Icon
            type="check"
            className="spe"
            style={{ fill: '#00eacf', width: '60px', height: '60px' }}
          />
        }
        title={txtStatus}
        message={<div style={{ textAlign: 'left', textIndent: '2rem' }}>{props.tbdFlag}</div>}
      />
      <WhiteSpace />
      <Button
        disabled={isBtnDisable}
        type="primary"
        inline
        style={{ marginLeft: '5vw', marginTop: '20px', width: '90vw' }}
        onClick={() => {
          router.replace({
            pathname: '/',
          });
        }}
      >
        关闭页面
      </Button>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state.sign,
  };
}

export default connect(mapStateToProps)(ResultPage);
