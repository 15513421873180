import request from '../utils/request';

// 测试服务器：
const prefix = process.env.weChat_url_prefix;

// 获取二维码支付页面参数
export function getPayInfo(props) {
  return request(
    process.env.NODE_ENV === 'production'
      ? prefix + 'cuishou/getPayInfo'
      : '/sinosafe_api/cuishou/getPayInfo',
    'post',
    props,
  );
}
