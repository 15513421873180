// import fetch from 'dva/fetch';
import router from 'umi/router';
import * as utils from './common';
import md5 from 'md5-js';
const fetch = require('dva').fetch;

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 * anjucUserType:0担保人、1借款人
 */
export default async function request(url, method = 'post', data) {
  const { file, base64Str, certFront, certBack } = data;
  const options = {
    method: method,
    headers: {
      // 'Content-Type': file ? "multipart/form-data" : 'application/json',
      version: '20200323',
      token: utils.getUserInfo() ? utils.getUserInfo().token : '',
      cus_id: utils.getUserInfo() ? utils.getUserInfo().user_id : '',
      openid: localStorage.openid ? localStorage.openid : '',
      anjucusertype: localStorage.role === 'A' ? '0' : '1',
      credit_coordinate: localStorage.credit_coordinate ? localStorage.credit_coordinate : '',
      accessToken: localStorage.accessToken ? localStorage.accessToken : '',
    },
    credentials: 'include', // 是否携带cookie，默认为omit,不携带; same-origi,同源携带; include,同源跨域都携带
  };
  if (method === 'get') {
    url += '?' + parseQuery(data);
  } else {
    // 后台历史遗留问题，图片上传接口不能拼接参数
    if (!base64Str) {
      url += '?' + parseQuery(data);
      // url += '?' + encodeURIComponent(parseQuery(data));
    }
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(data);

    // if (file || certFront) {
    //   const formdata = new FormData();
    //   for (var key in data) {
    //     if (key === 'file') {
    //       formdata.append('files', file, file.name);
    //     } else if (key === 'base64Str') {
    //       formdata.append('base64Str', base64Str);
    //     } else if (key === 'certFront') {
    //       formdata.append('certFront', certFront, certFront.name);
    //     } else if (key === 'certBack') {
    //       formdata.append('certBack', certBack, certBack.name);
    //     } else {
    //       formdata.append(key, data[key]);
    //     }
    //   }
    //   options.body = formdata;
    // } else {
    //   options.headers['Content-Type'] = 'application/json';
    //   options.body = JSON.stringify(data);
    // }
  }
  if (!process.env.prod) {
    console.group('request');
    console.log('options.headers', JSON.stringify(options.headers));
    console.log('options.body', JSON.stringify(options.body));
    console.log("url", url);
  }
  const response = await fetch(url, options);
  checkStatus(response);
  const result = await response.json();
  if (!process.env.prod) {
    console.log('result', JSON.stringify(result));
    console.groupEnd();
  }
  // 登录失效
  if (result && result.code === 3002) {
    // window.g_app._store.dispatch({
    //   type: 'index/showGlobalModal',
    //   msg: result.msg
    // });
    localStorage.clear();
    router.push('/');
  }
  return result;
}

const parseQuery = obj => {
  let str = '';
  for (let key in obj) {
    const value = typeof obj[key] !== 'string' ? JSON.stringify(obj[key]) : obj[key];
    str += '&' + key + '=' + value;
  }
  return str.substr(1);
};


function params_md5(data) {
  data = data || {};
  let sortData = '';
  let timestamp = new Date().getTime();
  data.timestamp = timestamp;
  //按对象属性进行排序
  let objkeys = Object.keys(data).sort();
  objkeys.forEach(val => {
    sortData += (val + "=" + data[val]);
  });
  //拼接字符串，并md5加密
  let sign = md5(sortData + '*Haxb_*Cnmobi_*20170807_*XinBao-SignSecret_*By5Hirson');
  return { sign, timestamp }
}
