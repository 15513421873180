import React, { useState, useEffect, useRef, useCallback } from 'react';
import { fetchMock } from '../../../mock/fetchMock';
import { List, Button, WhiteSpace, Modal, Checkbox, Toast, Text } from 'antd-mobile';
import { connect } from 'dva';
import router from 'umi/router';
import acccountCss from '../account/account.css';
import signCss from '../sign/sign.css';
import * as utils from '../utils/common';
import { Form, Input } from 'antd';
import * as reg from '../utils/reg';
import * as signService from '../services/sign';
import { stopRecord } from '../utils/situUtils';
// const fetch = require('dva').fetch;
const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;
const { Search } = Input;
const CheckboxItem = Checkbox.CheckboxItem;
const AgreeItem = Checkbox.AgreeItem;
const countdown = process.env.countdown;

function Sign(props) {
  const [isShowContract, setIsShowContract] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({});
  const [contracts, setContracts] = useState({});
  const [htmlData, setHtmlData] = useState(`加载中`);
  const [counter, setCounter] = useState();

  const scrollEl = useRef();

  const [refresh, setRefresh] = useState(false);

  const [isEndRead, setIsEndRead] = useState(false);
  const [isEndTime, setIsEndTime] = useState(false);
  const [btnTxt, setBtnTxt] = useState('请先完整阅读合同文本（' + countdown + 's）');

  const [showMsgMask, setShowMsgMask] = useState(false);
  const [btnText, setBtnText] = useState('获取验证码');
  const [telphone, setTelphone] = useState('');
  const [signSmsObject, setSignSmsObject] = useState({});
  const [form] = Form.useForm();
  const { getFieldValue, validateFields, setFieldsValue } = form;

  const onScroll = useCallback(event => {
    // console.log('clientHeight', event.target.clientHeight);
    // console.log('scrollTop', event.target.scrollTop);
    // console.log('scrollHeight', event.target.scrollHeight);
    // 滑到底部了
    if (event.target.clientHeight + event.target.scrollTop + 100 > event.target.scrollHeight) {
      // console.log('------滑到底部了------');
      setIsEndRead(true);
    }
  }, []);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  useEffect(() => {
    if (isEndRead && isEndTime) {
      setBtnTxt('我已阅读');
    }
  }, [isEndRead, isEndTime]);

  useEffect(() => {
    if (!isShowContract) {
      return;
    }
    scrollEl.current.addEventListener('scroll', onScroll);
    return () => scrollEl.current.removeEventListener('scroll', onScroll);
  }, [isShowContract, onScroll]);

  useEffect(() => {
    document.title = '投保意愿确认';
    console.log('serno', props.location.query.serno);
    console.log('signType', props.location.query.signType);
    setTelphone(props.location.query.telphone);
    const maps = {
      serno: props.location.query.serno,
      signType: props.location.query.signType,
      accessToken: localStorage.accessToken,
      taskId: localStorage.getItem('taskId'),
    };
    props.dispatch({
      type: 'sign/getSignContractList',
      maps,
      callback: r => {
        setContracts(r);
      },
    });

    // 保存录屏任务记录
    props.dispatch({
      type: 'sign/saveScreenInfo',
      maps,
      callback: r => {
        if (!process.env.prod) {
          Toast.loading('保存录屏任务记录成功', 5);
        }
      },
    });

    // fetchMock('/api/getContracts', {})
    //   .then(response => response.json())
    //   .then(res => {
    //     console.log(res);
    //     props.dispatch({
    //       type: 'sign/setContracts',
    //       contracts: res.data,
    //     });
    //     setContracts(res.data);
    //   });
    console.log(props);
  }, [props]);

  useEffect(() => {
    if (isShowContract) {
      // Add event listener using our hook
    }
  }, [isShowContract]);

  // 合同预览
  function getHtmlData(item) {
    const maps = {
      serno: props.location.query.serno,
      templateId: item.id,
      imgType: item.img_type,
      accessToken: localStorage.accessToken,
    };
    let url = signService.getPreviewTemplate() + '?' + utils.parseQuery(maps);

    // fetch 和 XMLHttpRequest 两种方式都可以
    // console.log('url', url);
    // fetch(url, {
    //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //   // headers: {
    //   //   'content-type': 'application/json',
    //   // },
    //   method: 'GET', // *GET, POST, PUT, DELETE, etc.
    // })
    //   .then(response => response.text())
    //   .then(res => {
    //     console.log('getHtmlData', res);
    //     setHtmlData(res);
    //   });

    try {
      var xmlhttp;
      // code for IE7+, Firefox, Chrome, Opera, Safari
      xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = function() {
        Toast.hide();
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
          // console.log(xmlhttp.responseText);
          setHtmlData(xmlhttp.responseText);
          setIsShowContract(true);
          if (item.isReaded || item.isChecked) {
            setIsEndTime(true);
            setIsEndRead(true);
            setBtnTxt('我已阅读');
          } else {
            setIsEndTime(false);
            setIsEndRead(false);
            setBtnTxt('请先完整阅读合同文本（5s）');
            btnTimeCounter();
          }
        } else {
          setHtmlData('加载中');
        }
      };
      xmlhttp.open('GET', decodeURIComponent(url), true);
      xmlhttp.send();
      Toast.loading('加载中', 5);
    } catch (e) {
      props.dispatch({
        type: 'index/showGlobalModal',
        msg: '预览合同失败，请稍后再试',
      });
    }
  }

  function btnTimeCounter() {
    var c = 0;
    let e = Number(process.env.countdown);
    let t = setInterval(() => {
      if (e > c) {
        setBtnTxt('请先完整阅读合同文本' + '（' + (e - c) + 's）');
      } else {
        setBtnTxt('请先完整阅读合同文本');
        setIsEndTime(true);
        clearInterval(t);
      }
      c++;
    }, 1000);
    setCounter(t);
  }

  function countDown() {
    if (btnText === '获取验证码') {
      const maps = {
        serno: props.location.query.serno,
        accessToken: localStorage.accessToken,
      };
      props.dispatch({
        type: 'sign/sendSignSms',
        maps,
        callback: r => {
          if (r.data.signAccount && r.data.projectCode) {
            console.log(r);
            setSignSmsObject(r.data);
            var s = 60;
            let i = setInterval(() => {
              setBtnText(s + '秒后重试');
              s--;
              if (s === 0) {
                clearInterval(i);
                setBtnText('获取验证码');
              }
            }, 1000);
            Toast.success('短信已发送', 1);
          } else {
            Toast.offline('获取签署短信数据缺失，稍后重试', 1);
          }
        },
      });
    } else {
      // 倒计时还没结束，等待
    }
  }

  const handleSubmit = async () => {
    // console.log('handleSubmit--->');
    try {
      const values = await validateFields();
      // console.log(values);
      const maps = {
        signAccount: signSmsObject.signAccount,
        projectCode: signSmsObject.projectCode,
        serno: props.location.query.serno,
        signType: props.location.query.signType,
        msgCode: values.code,
        accessToken: localStorage.accessToken,
        wxface: localStorage.wxface ? localStorage.wxface : '-',
        bizId: localStorage.bizId ? localStorage.bizId : '-',
        // faceidResult: localStorage.faceidResult ? JSON.stringify(localStorage.faceidResult) : '',
      };
      //先思图上传，后确认签署
      const currentWaitSign = props.currentWaitSign ? JSON.parse(props.currentWaitSign) : {};
      utils.showLoading();
      stopRecord(currentWaitSign)
        .then(r => {
          // 这里如果sdk返回了状态，说明已经sdk内部重试上传片段过了（如需），已经无能为力，只能结束流程
          props.dispatch({
            type: 'sign/confirmSmsAndSign',
            maps,
            callback: r => {
              console.log(r);
              localStorage.removeItem('bizId');
              setShowMsgMask(false);
              router.replace({
                pathname: '/Result',
              });
            },
          });
        })
        .catch(() => {
          // 如果是超时或其他异常，让用户重试
          alert(
            '提示',
            '当前网络可能不佳，请重试，如果多次重试后仍然无法完成签署，请重新操作或联系客户经理，',
            [
              { text: '我再试试', onPress: () => console.log('cancel'), style: 'default' },
              {
                text: '暂不签署',
                onPress: () => {
                  router.replace({
                    pathname: '/',
                  });
                },
              },
            ],
          );
        })
        .finally(() => {
          utils.hideLoading();
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={acccountCss.container}
      style={{ padding: '20px 0px', fontFamily: 'PingFang SC' }}
    >
      {contracts && contracts.length > 0 ? (
        <div>
          <List
            renderHeader={() => '请逐一点击阅读以下文件并确认阅读文件内容后方可签署'}
            className="my-list"
          >
            {contracts.map((d, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                    height: '45px',
                  }}
                >
                  <AgreeItem
                    style={{
                      marginBottom: '26px',
                    }}
                    key={index}
                    checked={contracts[index].isChecked}
                    onChange={() => {
                      if (!d.isReaded) {
                        Toast.info('请先点击阅读合同', 1);
                        return;
                      } else {
                        console.log('index', index);
                        console.log('contracts[index].isChecked', contracts[index].isChecked);
                        contracts[index].isChecked = !contracts[index].isChecked;
                        // 强制刷新
                        setRefresh(!refresh);
                      }
                    }}
                  ></AgreeItem>
                  <div>
                    <div
                      style={{
                        marginLeft: '5px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'DarkGray',
                        }}
                      >
                        我已阅读并确认
                      </div>
                      <div
                        style={{
                          fontSize: '12px',
                          color: '#0f8ee9',
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          setCurrentIndex(index);
                          getHtmlData(d);
                        }}
                      >
                        {d.img_name}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </List>

          <div
            style={{
              margin: '10px',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            特别提示：
            <br />
            上述投保单及相关文书通过电子签名(“安心签”）方式签署，您输入验证码并点击同意即视为签署成功。为保障您的权益，请您认真仔细阅读，如果您对其中的内容有不理解或异议的，请联系您的客户经理进行咨询；如果您发现其中的内容错误或与您本人意愿相违背，请勿点击同意，可联系您的客户经理进行变更或重新申请投保。
          </div>
          <WhiteSpace size="xl" />
          <div
            style={{
              display: 'flex',
              width: '100vw',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <Button
              type="ghost"
              inline
              style={{ width: '35vw' }}
              onClick={() => {
                alert('提示', '是否取消签署？', [
                  { text: '否', onPress: () => console.log('cancel'), style: 'default' },
                  {
                    text: '是',
                    onPress: () => {
                      // router.go(-1);
                      router.replace({
                        pathname: '/',
                      });
                    },
                  },
                ]);
              }}
            >
              取消签署
            </Button>
            <Button
              type="primary"
              inline
              style={{ width: '35vw' }}
              onClick={() => {
                let l = contracts.length;
                let canSign = true;
                for (var i = 0; i < l; i++) {
                  if (!contracts[i].isChecked) {
                    canSign = false;
                    break;
                  }
                }
                if (!canSign) {
                  Toast.offline('请勾选并确认阅读所有合同');
                  return;
                }
                setShowMsgMask(true);
              }}
            >
              同意签署
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ width: '100%', height: '500px' }}>
          <img
            style={{
              position: 'relative',
              left: 'calc((100% - 160px) / 2)',
              top: '150px',
            }}
            width="160px"
            height="160px"
            src={require('../../assets/account/icon_no message@2x.png')}
          ></img>
        </div>
      )}

      <Modal
        popup
        closable
        platform="ios"
        visible={isShowContract}
        onClose={() => {
          setIsShowContract(false);
        }}
        animationType="slide-up"
        afterClose={() => {
          clearInterval(counter);
        }}
      >
        <div
          style={{
            height: '85vh',
          }}
        >
          <div
            style={{
              height: '70vh',
              overflow: 'scroll',
            }}
            ref={scrollEl}
          >
            <div
              dangerouslySetInnerHTML={{ __html: htmlData }}
              style={{
                width: '100vw',
                paddingTop: '100px',
                // textAlign: 'left',
                // paddingBottom: '15vh',
                // padding: '100px 0',
                // height: '85vh',
                // overflowY: 'auto',
                // WebkitOverflowScrolling: 'touch',
              }}
            ></div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '15vh',
              width: '100vw',
              position: 'fixed',
              bottom: '0px',
              backgroundColor: '#fff',
            }}
          >
            <Button
              disabled={!isEndRead || !isEndTime}
              style={{
                width: '90vw',
              }}
              type="primary"
              onClick={() => {
                setIsShowContract(false);
                contracts[currentIndex].isReaded = true;
              }}
            >
              {btnTxt}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={showMsgMask}
        transparent
        closable
        maskClosable={false}
        onClose={() => {
          setShowMsgMask(false);
          setFieldsValue({
            code: '',
          });
        }}
        title="短信验证码校验"
        footer={[
          {
            text: '确认',
            onPress: handleSubmit,
          },
        ]}
        wrapProps={{ onTouchStart: utils.onWrapTouchStart }}
        className={signCss.modal}
      >
        <div style={{ height: 100, textAlign: 'left' }}>
          <Text
            style={{
              color: 'rgba(153,153,153,1)',
              fontWeight: '500',
              fontSize: '14px',
              textAlign: 'left',
              textIndent: '2rem',
            }}
          >
            短信签署验证码将发送至
            {utils.phoneDesensitization(telphone)}。
          </Text>
          <WhiteSpace />
          <Form form={form}>
            <Form.Item name="code" rules={reg.notNull_rules}>
              <Search
                placeholder="输入验证码"
                enterButton={btnText}
                size="large"
                onSearch={countDown}
              />
            </Form.Item>
          </Form>
          {/* <Text
            style={{
              color: 'rgba(153,153,153,1)',
              fontWeight: '500',
              fontSize: '14px',
              // float: 'right',
              right: '1rem',
              textDecoration: 'underline',
            }}
          >
            收不到验证码？点此处试试
          </Text> */}
          <WhiteSpace />
        </div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state.sign,
  };
}

export default connect(mapStateToProps)(Sign);
